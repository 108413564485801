import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Input, Slider, Button, TabsList } from "../../components/ui";
import Trades from "../../components/home/trades";
const Home = () => {
  const [step, setStep] = useState<"OPEN" | "CLOSED">("OPEN");
  const [searchText, setSearchText] = useState("");
  const [onTabOpen, setOnTabOpen] = useState(false);
  return (
    <div className="container mx-auto px-4 py-4">
      <div className="flex">
        <TabsList
          data={[
            { label: "Open trades", value: "OPEN" },
            { label: "Closed trades", value: "CLOSED" },
          ]}
          activeTab={step}
          onChange={(tab, i) => {
            setStep(tab.value === "OPEN" ? "OPEN" : "CLOSED");
            setOnTabOpen(tab.value === "OPEN" ? true : false);
          }}
        />
        <div className="flex gap-4 ml-auto relative">
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
          {/* <Button>Record Payment</Button> */}
        </div>
      </div>
      <div>
        <div className="h-full pt-4">
          <Trades status={step} searchText={searchText} displayMode="table" />
        </div>
      </div>
    </div>
  );
};

export default Home;
