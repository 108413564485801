import React, { FC, useEffect } from 'react';
import comingsoon from '../../assets/images/coming_soon.gif';
import { FaChevronRight } from "react-icons/fa6";
import EmptyProfile from "../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import market from "../../assets/images/market.png";
import timer from "../../assets/images/timer-img.png";
import tickmark from "../../assets/images/tickmark.png";
import useAllTraders from "../../hooks/useAllTraders";
import { useDispatch } from "react-redux";
import { updateTraderId } from "../../reducers/tradeSlice";
import { getMaskedMobileNumber } from '../../helpers/get-currency-number';
import { TTrader } from '../../types';

const AllCustomers:FC <{
  traders: TTrader[];
}> = ({traders }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { traders} = useAllTraders();

  return (
    <div>
    <div
      style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
      className="ml-3 mr-3 border-gray"
    >
      {/* idhi manam own ga icham like manaki kavalsina content ki margin vachela */}
      <div
        className="overflow-x-auto"
        style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
      >
        <div
          className="overflow-y-auto"
          style={{ height: "calc(100vh - 10rem)" }}
        >
          <div className="flex bg-light-primary text-sm font-semibold py-2 sticky top-0 bg-gray-50">
            <span className="grow-[0.3] basis-0 px-2 text-center px-2">#</span>
            <span className="grow-[1.3] basis-0 px-2">Trader</span>
            <span className="grow-[1] basis-0 px-2">Trader Type</span>
            <span className="grow-[1.2] basis-0 px-2 mr-3">Market</span>
            <span className="grow-[1] basis-0 px-2 mr-6">
              Pending Balance(₹)
            </span>
            <span className="grow-[1] basis-0 px-2 mr-6">Trade Limit(₹)</span>
            <span className="grow-[1] basis-0 px-2">Status</span>
          </div>
          <div className="text-sm">
            <ul>
              {traders.map((trader, index) => (
                <li
                  onClick={() => {
                    navigate(`/allDetails/${trader.traderId}`);
                    dispatch(
                      updateTraderId({
                        traderId: trader.traderId,
                        tradeStatus: "ALL",
                      })
                    );
                  }}
                  className="flex p-4 cursor-pointer border-b mb-4"
                >
                  <span className="grow-[0.2] basis-0 text-center font-semibold mt-1">
                    {index + 1}
                  </span>
                  <div className="grow-[1.5] basis-0 flex ml-4">
                    <div>
                      {(trader?.profilePic === "" ||
                        trader?.profilePic === null) && (
                        <img
                          src={EmptyProfile}
                          alt=""
                          className="w-10 h-10 mt-1 mr-2"
                        />
                      )}
                      {trader?.profilePic !== "" &&
                        trader?.profilePic !== null && (
                          <img
                            className="w-10 h-10 rounded-full object-cover mr-2 mt-1"
                            src={trader?.profilePic}
                          />
                        )}
                    </div>
                    <div>
                      <span className="font-semibold">{trader.tradeName}</span>
                      <br></br>
                      <span className="">
                        Trader-
                        <span className="color-blue">{trader.traderId}</span> |{" "}
                        {getMaskedMobileNumber(trader.mobile || "")}
                      </span>
                      <br></br>
                      <span className="">{trader.name}</span>
                    </div>
                  </div>
                  <span className="grow-[1] basis-0 px-2 font-semibold">
                    {trader.tradeType ?? "-"}
                  </span>
                  <div className="grow-[1.5] basis-0 flex">
                    <div>
                      <img
                        className="w-10 h-10 rounded-full object-cover mr-1"
                        src={market}
                      ></img>
                    </div>
                    <div>
                      <span className="font-semibold mt-6 ml-4">{trader.marketName ?? "-"}</span>
                    </div>
                  </div>

                  {trader.tradeType === "SOURCE" && (
                    <span className="grow-[1] basis-0">
                      <div>
                        {trader.amtDetails !== null ? (
                          <span className=" px-2 color-orange text-sm font-semibold">
                            {" "}
                            {(
                              trader.amtDetails.paidReceived -
                              trader.amtDetails.paidAmount
                            ).toFixed(2)}
                          </span>
                        ) : (
                          <span className=" px-2 color-orange text-sm font-semibold">
                            0
                          </span>
                        )}

                        <br />
                        <span className="color-black ml-1 text-sm">
                          To Be Paid
                        </span>
                      </div>
                    </span>
                  )}

                  {trader.tradeType !== "SOURCE" && (
                    <span className="grow-[1] basis-0">
                      <div>
                        {trader.amtDetails !== null ? (
                          <span className=" px-2 color-primary text-sm font-semibold">
                            {" "}
                            {(
                              trader.amtDetails.paidReceived -
                              trader.amtDetails.paidAmount
                            ).toFixed(2)}
                          </span>
                        ) : (
                          <span className=" px-2 color-primary text-sm font-semibold">
                            0
                          </span>
                        )}
                        <br />

                        <span className="color-black ml-1 text-sm">
                          To Be Received
                        </span>
                      </div>
                    </span>
                  )}
                  <div className="grow-[1] basis-0 px-2 ml-4">
                    <span className="font-semibold text-sm">
                      {trader.tradeLimit}
                    </span>
                    {trader.amtDetails !== null && (
                      <p className="text-sm">
                        Avl Limit:{" "}
                       <span className="font-semibold">--
                       {/* {(
                          trader.tradeLimit - trader.amtDetails.paidReceived
                        ).toFixed(2)} */}
                       </span>
                      </p>
                    )}
                  </div>

                  <span className="grow-[1] basis-0 px-2 flex ml-2 font-semibold text-base">
                    {trader.status}
                  </span>
                  <div className="">
                    {" "}
                    <FaChevronRight />{" "}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
  );
};

export default AllCustomers;