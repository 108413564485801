import EmptyProfile from "../../../assets/images/profile.jpg";
import mandii from "../../../assets/images/mandii.png";
import timer from "../../../assets/images/timer-img.png";
import phone from "../../../assets/images/phone-logo.png";
import document from "../../../assets/images/document.png";
import vehicle from "../../../assets/images/vehicle.png";
import { FaChevronRight } from "react-icons/fa6";
import location from "../../../assets/images/location.png";
import tickmark from "../../../assets/images/tickmark.png";
import { TTraderId } from "../../../types";
import DocumentPreview from "../../../components/commons/document-preview";
import { useState } from "react";
import AddTrader from "../AddTrader";
import Modal from "../../../components/ui/modal";
import { FaEdit } from "react-icons/fa";
import { Button, CircularProgress } from "@mui/material";
import market from "../../../assets/images/market.png";
// import marketImgg from "../../assets/images/Group.png";
import { getMaskedMobileNumber } from "../../../helpers/get-currency-number";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateTraderId } from "../../../reducers/tradeSlice";

const TradeDetails: React.FC<{
  trader: TTraderId | null | undefined;
  onSubmit: () => void;
  loading: boolean;
  activeTab: string;
}> = ({ trader, onSubmit, loading, activeTab }) => {
  let [traderSummary, setTraderSummary] = useState({
    TotalReceivable: 0,
    PlatformFees: 0,
    ReceivedTillDate: 0,
    BalanceReceivable: 0,
  });
  const calculateTotal = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.totalReceivable;
      } else {
        total += trade.totalPayable;
      }
    });
    return total.toFixed(2);
  };

  const calculatePlatform = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.srcPf;
      } else {
        total += trade.destPf;
      }
    });
    return total.toFixed(2);
  };

  const calculateTillDate = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.outwardPmt;
      } else {
        total += trade.inwardPmt;
      }
    });
    return total.toFixed(2);
  };

  const calculateBalance = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.totalReceivable - trade.inwardPmt;
      } else {
        total += trade.totalPayable - trade.outwardPmt;
      }
    });
    return total.toFixed(2);
  };
  const [addTrader, setAddTrader] = useState(false); // edit click chesaka popup kosam

  const dispatch = useDispatch();
  const navigate = useNavigate();
   
  return (
    <>
      <div
        className="flex overflow-y-auto mr-4"
        style={{ height: "calc(100vh - 10rem)" }}
      >
        <div className="w-[40%]">
          <div>
            <div className="h-[250px] border-gray m-[20px] br-10">
              <div className="h-[50px] border-b bg-light-primary br-10 flex items-center justify-between">
                <div className="flex">
                  <span>
                    <img
                      src={EmptyProfile}
                      alt="EmptyProfile"
                      className="h-8 ml-[20px]"
                    />
                  </span>
                  <span className="mt-1 font-semibold text-base">
                    Trader Details
                  </span>
                </div>

                <div className="">
                  <Button
                    onClick={() => {
                      setAddTrader(true);
                    }}
                  >
                    <FaEdit className="" />
                    <span className="ml-1 mt-0.5">Edit</span>
                  </Button>
                </div>
              </div>

              <div>
                <Modal
                  open={addTrader}
                  onClose={() => {
                    setAddTrader(false);
                  }}
                  width="full"
                >
                  <AddTrader
                    mode="EDIT"
                    onRefresh={() => {
                      onSubmit();
                    }}
                    onClose={() => {
                      setAddTrader(false);
                    }}
                  />
                </Modal>
              </div>

              <div className="m-[20px] flex">
                <div>
                  {(trader?.profilePic === "" ||
                    trader?.profilePic === null) && (
                    <img
                      src={EmptyProfile}
                      alt=""
                      className="w-10 h-10 mt-1 mr-2"
                    />
                  )}
                  {trader?.profilePic !== "" && trader?.profilePic !== null && (
                    <img
                      src={trader?.profilePic}
                      alt=""
                      className="h-10 w-10 br-100 mt-1"
                    />
                  )}
                </div>
                <div className="justify-between flex">
                  <div className="ml-[10px]">
                    <span className="font-semibold mr-2 text-sm">
                      {trader?.tradeName}
                    </span>
                    <br></br>
                    <span className="text-sm">
                      Trader ID -
                      <span className="color-blue">{trader?.traderId}</span> |
                      <span className="ml-1 text-sm">
                        {" "}
                        {getMaskedMobileNumber(trader?.mobile || "")}
                      </span>
                    </span>
                    <br></br>
                    <span className="color-sub-text text-sm">
                      {trader?.name}
                    </span>
                  </div>
                  <div className="ml-[60px]">
                    <span className="mt-2 text-sm color-sub-text">
                      ONO RISK SCORE
                    </span>{" "}
                    <br />
                    <span className="text-sm font-semibold">
                      ORS- {trader?.ors} / 10
                    </span>
                  </div>
                </div>
              </div>
              <div className="border-b"></div>

              <div className="flex mt-6 justify-between">
                <div className="ml-[20px]">
                  <span className="color-sub-text">Trader Type</span>
                  <br></br>
                  <span className="font-semibold text-sm">
                    {trader?.tradeType}
                  </span>
                </div>
                <div className="border ml-4 w-[0px] h-[50px]  border-gray-300"></div>

                <div className="mt-0.5">
                  <span className="color-sub-text">Avl Limit (₹)</span>
                  <br></br>
                  <span className="font-semibold text-base ml-6">
                    {/* avl limit ivvali */} --
                  </span>
                </div>
                <div className="border ml-4 w-[0px] h-[50px]  border-gray-300"></div>

                <div className="mr-[20px]">
                  <span className="color-sub-text">Status</span> <br />
                  <span className="font-semibold text-sm">
                    {trader?.status}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className=" h-auto border-gray ml-[20px] mr-[20px] br-10 pb-4">
            <div className="h-[50px] border-b bg-light-primary br-10 flex items-center">
              <img src={mandii} alt="mandii" className="h-6 ml-[20px] " />{" "}
              <span className="ml-[10px] font-semibold">Business Details</span>
            </div>

            <div className="mt-4 flex flex-col sm:flex-row justify-between">
              {/* Trade Name Section */}
              <div className="flex items-center">
                {/* Profile Image */}
                <div>
                  <img
                    src={EmptyProfile}
                    alt=""
                    className="h-[30px] w-[30px] ml-[10px] mr-[10px] mt-1"
                  />
                </div>

                {/* Trade Name Text */}
                <div>
                  <span className="color-sub-text text-sm sm:text-base">
                    Trade Name
                  </span>
                  <br />
                  {trader?.tradeName ? (
                    <span className="text-sm font-semibold">
                      {trader.tradeName}
                    </span>
                  ) : (
                    <span className="text-sm text-gray-500">--</span>
                  )}
                </div>
              </div>

              {/* Trader Name Section */}
              <div
                className={`mt-4 sm:mt-0 ${
                  trader?.traderName ? "sm:px-12" : ""
                }`}
              >
                <span className="color-sub-text text-sm sm:text-base">
                  Trader Name
                </span>
                <br />
                {trader?.traderName ? (
                  <span className="text-sm font-semibold">
                    {trader.traderName}
                  </span>
                ) : (
                  <span className="text-sm text-gray-500">--</span>
                )}
              </div>
            </div>

            <div className="mt-4 flex flex-col sm:flex-row justify-between">
              {/* Business Type Section */}
              <div className="flex items-start">
                <div>
                  <span className="color-sub-text ml-[50px] text-sm sm:text-base">
                    Business Type
                  </span>
                  <br />
                  {trader?.regType ? (
                    <span className="font-semibold text-sm ml-[50px]">
                      {trader.regType}
                    </span>
                  ) : (
                    <span className="ml-[60px] text-gray-500">--</span>
                  )}
                </div>
              </div>

              {/* Short Code Section */}
              <div className={`mt-4 sm:mt-0 sm:mr-[60px]`}>
                <span className="color-sub-text text-sm sm:text-base">
                  Short Code
                </span>
                <br />
                {trader?.tradeScode ? (
                  <span className="text-sm font-semibold">
                    {trader.tradeScode}
                  </span>
                ) : (
                  <span className="text-gray-500">--</span>
                )}
              </div>
            </div>

            <div className="flex">
              <div>
                <img
                  src={location}
                  alt=""
                  className="h-[30px] w-[30px] ml-[10px] mr-[10px] mt-5"
                />
              </div>
              <div className="mt-4">
                <span className="color-sub-text">Business Address</span>
                <br />
                <span className="font-semibold text-sm">
                  {trader?.addressLine &&
                  trader?.dist &&
                  trader?.state &&
                  trader?.pincode ? (
                    <span>
                      {trader.addressLine}
                      <br />
                      {trader.dist}, {trader.state} {trader.pincode}
                    </span>
                  ) : (
                    "--"
                  )}
                </span>
              </div>
            </div>

            <div className="mt-4 flex flex-col sm:flex-row justify-between">
              {/* Market Name Section */}
              <div className="flex items-center">
                <div>
                  <img
                    src={market}
                    alt=""
                    className="h-[30px] w-[30px] ml-[10px] mr-[10px] mt-1"
                  />
                </div>
                <div>
                  <span className="color-sub-text text-sm sm:text-base">
                    Market Name
                  </span>
                  <br />
                  {trader?.marketName ? (
                    <span className="text-sm font-semibold">
                      {trader.marketName}
                    </span>
                  ) : (
                    <span className="text-gray-500">--</span>
                  )}
                </div>
              </div>

              {/* Shop Number Section */}
              <div className={`mt-4 sm:mt-0 sm:mr-[30px]`}>
                <span className="color-sub-text text-sm sm:text-base">
                  Shop Number
                </span>
                <br />
                {trader?.shopNum ? (
                  <span className="text-sm font-semibold">
                    {trader.shopNum}
                  </span>
                ) : (
                  <span className="text-gray-500">--</span>
                )}
              </div>
            </div>

            <div className="mt-4 flex flex-col sm:flex-row justify-between">
              <div className="ml-[50px]">
                <span className="color-sub-text">Trade Type</span>
                <br></br>
                {trader?.tradeType ? (
                  <span className="font-semibold text-sm">
                    {trader?.tradeType}
                  </span>
                ) : (
                  <span>--</span>
                )}
              </div>
              <div className={`mt-4 sm:mt-0 sm:mr-[30px]`}>
                  <span className="color-sub-text text-sm sm:text-base">
                    Trade Description
                  </span>
                  <br />
                  {trader?.shopNum ? (
                    <span className="text-sm font-semibold">
                      {trader.tradeDesc}
                    </span>
                  ) : (
                    <span className="text-gray-500">--</span>
                  )}
                </div>
                
            </div>

            <div className="justify-between mt-4 flex">
              <div className="flex">
                <div>
                  <img
                    src={phone}
                    className="h-8 w-8 ml-[10px] mr-[10px] mt-1"
                  />
                </div>
                <div>
                  <span className="color-sub-text">Mobile Number</span>
                  <br></br>
                  {trader?.mobile ? (
                    <span className="font-semibold text-sm">
                      {trader?.mobile}
                    </span>
                  ) : (
                    <span className="ml-4">--</span>
                  )}
                </div>
              </div>

              <div className="mr-[30px]">
                <span className="color-sub-text">Alternate Number</span>
                <br />
                {trader?.altMobile ? (
                  <span className="text-sm font-semibold">
                    {trader?.altMobile}
                  </span>
                ) : (
                  <span>--</span>
                )}
              </div>
            </div>

            <div className="justify-between mt-4 flex">
              <div className="flex">
                <div>
                  {trader?.rmProfilePic ? (
                    <img
                      src={trader?.rmProfilePic}
                      alt=""
                      className="w-8 h-8 ml-[20px] mr-[10px] mt-1 br-100"
                    />
                  ) : ( 
                  <img
                    src={EmptyProfile}
                    alt=""
                    className="w-8 h-8 ml-[10px] mr-[10px] mt-1"
                  />
                 )}
                </div>
                <div>
                  <span className="color-sub-text">Relationship Manager</span>
                  <br></br>
                  {trader?.relMgr ? (
                    <span className="font-semibold text-sm">
                      {trader?.relMgr}
                    </span>
                  ) : (
                    <span className="ml-4">--</span>
                  )}
                </div>
              </div>

              <div className="mr-[20px]">
                <span className="color-sub-text">RM Mobile Number</span>
                <br />
                {trader?.rmMobile ? (
                  <span className="text-sm font-semibold">
                    {trader?.rmMobile}
                  </span>
                ) : (
                  <span>--</span>
                )}
              </div>
            </div>
          </div>

          <div className=" h-auto border-gray m-[20px] br-10">
            <div className="h-[50px] border-b bg-light-primary br-10 flex items-center">
              <img src={document} alt="document" className="h-6 ml-[20px]" />
              <span className="ml-[10px] font-semibold">Documents</span>
            </div>

            <div>
              <div>
                <span className="color-sub-text mt-[30px] ml-4 mb-[10px]">
                  Aadhar Card
                </span>
                <div className="flex overflow-auto">
                  {trader?.kycDocs.filter((doc) => doc.type == "AADHAR")
                    .length !== 0 ? (
                    trader?.kycDocs
                      .filter((doc) => doc.type == "AADHAR")
                      .map((image, i) => (
                        <div
                          className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                          style={{
                            width: "33.333%",
                            height: "10rem",
                          }}
                        >
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div
                      className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      Not Uploaded Aadhar
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-[30px]">
                <span className="color-sub-text ml-4">
                  Permanent Account Number(PAN)
                </span>
                <div className="flex overflow-auto">
                  {trader?.kycDocs.filter((doc) => doc.type == "PAN").length !==
                  0 ? (
                    trader?.kycDocs
                      .filter((doc) => doc.type == "PAN")
                      .map((image, i) => (
                        <div
                          className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                          style={{
                            width: "33.333%",
                            height: "10rem",
                          }}
                        >
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div
                      className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      Not Uploaded PAN
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-[30px]">
                <span className="color-sub-text mt-[23px] ml-4 mb-[10px]">
                  Bank Statement
                </span>
                <div className="flex overflow-auto">
                  {trader?.kycDocs.filter((doc) => doc.type == "BANK_STATEMENT")
                    .length !== 0 ? (
                    trader?.kycDocs
                      .filter((doc) => doc.type == "BANK_STATEMENT")
                      .map((image, i) => (
                        <div
                          className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                          style={{
                            width: "33.333%",
                            height: "10rem",
                          }}
                        >
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div
                      className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      Not Uploaded Bank_Statement
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-[30px]">
                <span className="color-sub-text mt-[23px] mb-[10px] ml-4">
                  ITR Document
                </span>
                <div className="flex overflow-auto">
                  {trader?.kycDocs.filter((doc) => doc.type == "ITR").length !==
                  0 ? (
                    trader?.kycDocs
                      .filter((doc) => doc.type == "ITR")
                      .map((image, i) => (
                        <div
                          className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                          style={{
                            width: "33.333%",
                            height: "10rem",
                          }}
                        >
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div
                      className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      Not Uploaded ITR
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-[30px]">
                <span className="color-sub-text mt-[23px] mb-[10px] ml-4">
                  Trade/Mandi License
                </span>
                <div className="flex overflow-auto">
                  {trader?.kycDocs.filter((doc) => doc.type == "TRADE_LICENSE")
                    .length !== 0 ? (
                    trader?.kycDocs
                      .filter((doc) => doc.type == "TRADE_LICENSE")
                      .map((image, i) => (
                        <div
                          className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                          style={{
                            width: "33.333%",
                            height: "10rem",
                          }}
                        >
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div
                      className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      Not Uploaded Trade_License
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-[30px] mb-[20px]">
                <span className="color-sub-text mt-[23px] mb-[10px] ml-4">
                  Other Documents
                </span>
                <div className="flex overflow-auto">
                  {trader?.kycDocs.filter((doc) => doc.type == "OTHER")
                    .length !== 0 ? (
                    trader?.kycDocs
                      .filter((doc) => doc.type == "OTHER")
                      .map((image, i) => (
                        <div
                          className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                          style={{
                            width: "33.333%",
                            height: "10rem",
                          }}
                        >
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div
                      className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                      style={{
                        width: "33.333%",
                        height: "10rem",
                      }}
                    >
                      Not Uploaded Mandi_License
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {activeTab !== "Ledger" ? (
          <div className="w-[60%] mr-[10px]">
            <div>
              <div className="h-[80px] border-gray mt-[20px] mb-[20px] br-10">
                <div className="m-4 flex justify-between">
                  <div className="text-sm">
                    {trader?.tradeType === "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Total Receivable</p>
                        <p className="font-semibold">₹{calculateTotal()}</p>
                      </div>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Total Payable</p>
                        <p className="font-semibold">₹{calculateTotal()}</p>
                      </div>
                    )}
                  </div>
                  <div className="border ml-2 mt-1 w-[0px] h-[50px] border-gray-200"></div>

                  <div className="text-sm">
                    <p className="color-sub-text">Platform Fees</p>
                    {trader?.tradeType === "SOURCE" && (
                      <p className="font-semibold">₹{calculatePlatform()}</p>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <p className="font-semibold">₹{calculatePlatform()}</p>
                    )}
                  </div>
                  <div className="border ml-2 mt-1 w-[0px] h-[50px] border-gray-200"></div>

                  <div className="text-sm">
                    {trader?.tradeType === "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Received Till Date</p>
                        <p className="font-semibold">₹{calculateTillDate()}</p>
                      </div>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Paid Till Date</p>

                        <p className="font-semibold">₹{calculateTillDate()}</p>
                      </div>
                    )}
                  </div>
                  <div className="border ml-2 mt-1 w-[0px] h-[50px] border-gray-200"></div>

                  <div className="text-sm">
                    {trader?.tradeType === "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Balance Receivable</p>
                        <p className="font-semibold">₹{calculateBalance()}</p>
                      </div>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Balance Payable</p>

                        <p className="font-semibold">₹{calculateBalance()}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <span className="flex justify-center items-center h-screen">
                <CircularProgress />
              </span>
            ) : (
              <div>
                <div className="flex bg-light-primary br-10 h-[30px] justify-between items-center justify-center text-sm font-semibold">
                  <div className="ml-4 flex-1">Trade</div>
                  <div className="flex-1">From</div>
                  <div className="flex-1">Status</div>
                </div>

                {trader?.trades !== null ? (
                  trader?.trades.map((trade) => (
                    // <div
                    // onClick={() => {
                    //   navigate(`/allDetails/${trader.traderId}/${trade.tradeId}`);
                    //   dispatch(
                    //     updateTraderId({
                    //       traderId: trader.traderId,
                    //       tradeStatus: "ALL",
                    //     })
                    //   );
                    // }}
                    // >
                    <div className="border-gray br-10 mt-2">
                      <div className="flex justify-between">
                        <div className="ml-[20px] mb-[20px] mr-[20px] mt-[20px]">
                          <span className="color-blue">
                            Global ID: {trade.tradeId}
                          </span>
                          <br />
                          <span className="font-semibold">
                            Trade ID: {trade.traderSeq}
                          </span>
                        </div>

                        <div className="flex">
                          <span>
                            <img
                              src={EmptyProfile}
                              alt="EmptyProfile"
                              className="h-8 mt-6 mr-2"
                            />
                          </span>

                          <span className="mt-4">
                            <span className="font-semibold mr-2 text-sm">
                              {trade.tradeName}
                            </span>
                            <br></br>
                            <span className="text-sm">
                              Trader - {trade.traderId}|{" "}
                              {getMaskedMobileNumber(trade.mobile || "")}
                            </span>
                            <br></br>
                            <span className="color-sub-text text-sm">
                              {trade.tradeName}
                            </span>
                          </span>
                        </div>

                        <div className="flex">
                          <div>
                            <img
                              src={vehicle}
                              alt="vehicle"
                              className="h-8 mt-6"
                            />
                          </div>
                          <div className="ml-[8px] mt-4 text-sm">
                            <span className="font-semibold ">
                              {trade.tradeStatus}
                            </span>
                            <br></br>
                            <span>{trade.timeStamp}</span>
                          </div>
                        </div>
                        <span className="mt-[35px] mr-2">
                          {" "}
                          {/* <FaChevronRight /> */}
                        </span>
                      </div>
                      <div className="border-b mt-2"></div>
                      <div className="flex text-sm justify-between">
                        <div className="m-2">
                          <span className="color-sub-text ml-3">
                            Trade Type
                          </span>
                          <br></br>
                          <span className="font-semibold ml-3">
                            {trade.tradeMode}
                          </span>
                        </div>
                        <div className="border ml-2 mt-1 w-[0px] h-[50px] border-gray-200"></div>

                        <div className="m-2">
                          {trader?.tradeType === "SOURCE" && (
                            <div>
                              <span className="color-sub-text">
                                Total Receivable
                              </span>
                              <br />
                              <p className="font-semibold">
                                ₹ {trade.totalReceivable}
                              </p>
                            </div>
                          )}
                          {trader?.tradeType !== "SOURCE" && (
                            <div>
                              <span className="color-sub-text">
                                Total Payable
                              </span>{" "}
                              <br />
                              <p className="font-semibold">
                                ₹ {trade.totalPayable}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="border ml-4 mt-1 w-[0px] h-[50px]  border-gray-200"></div>

                        <div className="m-2">
                          {trader?.tradeType === "SOURCE" && (
                            <div>
                              <p className="color-sub-text">
                                Received Till Date
                              </p>
                              <p className="font-semibold">
                                ₹{trade.outwardPmt}
                              </p>
                            </div>
                          )}
                          {trader?.tradeType !== "SOURCE" && (
                            <div>
                              <p className="color-sub-text">Paid Till Date</p>
                              <p className="font-semibold">
                                ₹{trade.inwardPmt}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="border ml-4 mt-1 w-[0px] h-[50px]  border-gray-200"></div>

                        <div className="m-2">
                          {trader?.tradeType === "SOURCE" && (
                            <div>
                              <p className="color-sub-text">
                                Balance Receivable
                              </p>
                              <p className="font-semibold">
                                ₹
                                {(
                                  trade.totalReceivable - trade.outwardPmt
                                ).toFixed(2)}
                              </p>
                            </div>
                          )}
                          {trader?.tradeType !== "SOURCE" && (
                            <div>
                              <p className="color-sub-text">Balance Payable</p>
                              <p className="font-semibold">
                                ₹
                                {(trade.totalPayable - trade.inwardPmt).toFixed(
                                  2
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    // </div>
                  ))
                ) : (
                  <div
                    className="mt-2 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center br-10"
                    style={{
                      width: "98%",
                      height: "10rem",
                    }}
                  >
                    No Trades Available....
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div
            className=" w-[55%] mt-4 ml-2 mr-2 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center br-10"
            style={{
              height: "10rem",
            }}
          >
            No Data Available....
          </div>
        )}
      </div>
    </>
  );
};

export default TradeDetails;
