import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import DeviceLayout from "./layout";
import AuthLayout from "./layout/auth-layout";
import PageLayout from "./layout/page-layout";
import NotFound from "./pages/not-found";
import "./App.css";
import Home from "./pages/home";
import TradeDetail from "./pages/trade-detail";
import Myprofile from "./pages/my-profile";
import Customer from "./pages/customer/Customer";
import AllDetails from "./pages/customer/CustomerDetails/AllDetails";
function App() {
  return (
    <DeviceLayout>
      <AuthLayout>
        <PageLayout>
          <Suspense fallback={<>Loading...</>}>
            <Routes>
              <Route path="/" element={<Customer />} />
              <Route
                path="/trade-detail/:traderId/:tradeId"
                element={<TradeDetail />}
              />

              <Route path="/my-profile" element={<Myprofile />} />
              <Route path="/customers" element={<Customer />} />
              <Route path="/allDetails/:traderId" element={<AllDetails />} />
              <Route path="/trades" element={<Home />} />
              {/* <Route path="/allDetails/:traderId/:tradeId" element={< TradeDetail/>} /> */}
            </Routes>
          </Suspense>
        </PageLayout>
      </AuthLayout>
    </DeviceLayout>
  );
}

export default App;
