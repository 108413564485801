import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  TCrop,
  TDestinationPartner,
  TPaymentHistory,
  TProgressStatus,
  TProgressStatusObj,
  TTradeCompleteDetai,
  TTraderId,
  TTradeUpdateDetais,
} from "../types";

type TinitialState = {
  tradeDetails: TTradeCompleteDetai | null;
  tradeEditStatus: boolean;
  tradeId: number | null;
  traderId: number ;
  traderDetails: TTraderId | null;
  tradeStatus: string;
  partyData: TDestinationPartner[];
  selectedParty: TDestinationPartner | null;
  selectedSrcParty: TDestinationPartner | null;
  selectedDate: string;
  selectedCrop: TCrop | null;
  deletedCrop: TCrop | null;
  tradeUpdateDetails: TTradeUpdateDetais | null;
  selectedTime: string;
  progressStatus: TProgressStatusObj | null;
  paymentUpdateStatus: boolean;
  paymentHistoryObj: TPaymentHistory | null;
  editAdvancePayment: boolean;
};
const initialState: TinitialState = {
  tradeDetails: null,
  tradeEditStatus: false,
  tradeId: 0,
  traderId: 0,
  tradeStatus: "",
  traderDetails: null,
  partyData: [],
  selectedParty: null,
  selectedDate: moment().format("YYYY-MM-DD"),
  selectedCrop: null,
  deletedCrop: null,
  tradeUpdateDetails: null,
  selectedTime: "",
  progressStatus: null,
  paymentUpdateStatus: false,
  paymentHistoryObj: null,
  selectedSrcParty: null,
  editAdvancePayment: false,
};
const tradeSlice = createSlice({
  name: "trades",
  initialState: initialState,
  reducers: {
    updateTradeDetails: (
      state,
      action: PayloadAction<{
        tradeDetails: TTradeCompleteDetai | null;
      }>
    ) => {
      state.tradeDetails = action.payload.tradeDetails;
    },
    editTrade: (
      state,
      action: PayloadAction<{
        tradeEditStatus: boolean;
      }>
    ) => {
      state.tradeEditStatus = action.payload.tradeEditStatus;
    },
    updateTradeId: (
      state,
      action: PayloadAction<{
        tradeId: number | null;
      }>
    ) => {
      state.tradeId = action.payload.tradeId;
    },

    updateTraderId: (
      state,
      action: PayloadAction<{
        traderId: number;
        tradeStatus: "ALL" | "OPEN" | "CLOSED";
      }>
    ) => {
      state.traderId = action.payload.traderId;
      state.tradeStatus = action.payload.tradeStatus;
    //   if (action.payload.traderDetails != null) {
    //   state.traderDetails = action.payload.traderDetails;}
    },
    updatePartyData: (
      state,
      action: PayloadAction<{
        partyData: TDestinationPartner[];
      }>
    ) => {
      state.partyData = action.payload.partyData;
    },
    updateCropData: (
      state,
      action: PayloadAction<{
        selectedCrop: TCrop | null;
        selectedParty?: TDestinationPartner | null;
        deletedCrop?: TCrop | null;
        selectedSrcParty?: TDestinationPartner | null;
      }>
    ) => {
      // if (action.payload.selectedCrop) {

      // }

      if (action.payload.selectedParty != null) {
        state.selectedParty = action.payload.selectedParty;
      }
      if (action.payload.selectedSrcParty != null) {
        state.selectedSrcParty = action.payload.selectedSrcParty;
      }
      state.selectedCrop = action.payload.selectedCrop;
      if (action.payload.deletedCrop) {
        state.deletedCrop = action.payload.deletedCrop;
      }
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    arrivalTradeDetails: (
      state,
      action: PayloadAction<{
        tradeUpdateDetails: TTradeUpdateDetais | null;
      }>
    ) => {
      state.tradeUpdateDetails = action.payload.tradeUpdateDetails;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    updateProgressDetails: (
      state,
      action: PayloadAction<{
        progressStatus: TProgressStatusObj | null;
      }>
    ) => {
      state.progressStatus = action.payload.progressStatus;
    },
    updatePaymentUpdateStatus: (
      state,
      action: PayloadAction<{
        paymentUpdateStatus: boolean;
      }>
    ) => {
      state.paymentUpdateStatus = action.payload.paymentUpdateStatus;
    },
    getPaymentHistory: (
      state,
      action: PayloadAction<{
        paymentHistoryObj: TPaymentHistory | null;
      }>
    ) => {
      state.paymentHistoryObj = action.payload.paymentHistoryObj;
    },
    updateEditAdvPayment: (
      state,
      action: PayloadAction<{
        editAdvancePayment: boolean;
      }>
    ) => {
      state.editAdvancePayment = action.payload.editAdvancePayment;
    },
  },
});

export const {
  updateTradeDetails,
  editTrade,
  updateTraderId,
  updateTradeId,
  updatePartyData,
  setSelectedDate,
  updateCropData,
  arrivalTradeDetails,
  setSelectedTime,
  updateProgressDetails,
  updatePaymentUpdateStatus,
  getPaymentHistory,
  updateEditAdvPayment,
} = tradeSlice.actions;
export default tradeSlice.reducer;
