import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TTradeCompleteDetai } from "../../types";
import { TRootState } from "../../store";
import isArrivalDateStatus from "../../helpers/getArrivalDateStatus";
import { updateTradeDetails, updateTradeId } from "../../reducers/tradeSlice";
import { all } from "axios";
const useArrivalsView = ({
  traderId,
  tradeId,
  mode,
  type,
  typeFrom,
  closeModal,
  tabActive,
}: {
  traderId: number;
  tradeId: number;
  mode: boolean;
  type: string;
  typeFrom?: string;
  closeModal?: any;
  tabActive?: any;
}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handlePutCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const [alltradeDetail, setAllTradeDetail] =
    useState<TTradeCompleteDetai | null>(null);
  const {
    tradeDetails,
    arlDate,
    paymentHistoryObj,
    paymentUpdateStatus,
    adminId,
  } = useSelector((state: TRootState) => ({
    tradeDetails: state.trades.tradeDetails,
    arlDate: state.trades.tradeDetails?.arlDate,
    paymentHistoryObj: state.trades.paymentHistoryObj,
    paymentUpdateStatus: state.trades.paymentUpdateStatus,
    adminId: state.auth.adminId,
  }));
  const [arrivalDocuments, setOtherDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [pattiDocuments, setPattiDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [paymentDocuments, setPaymentDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [arrivalWeightDocuments, setArrivalWeightDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const calculateAdvancePaid = () => {
    let sum = 0;
    if (alltradeDetail != null) {
      if (alltradeDetail?.pmtHistory.length > 0) {
        for (let item of alltradeDetail?.pmtHistory) {
          if (
            item.pmtType.toUpperCase() == "ADVANCE" &&
            item.inOutWard == "INWARD"
          ) {
            sum += item.amount;
          }
        }
      }
    }
    return sum;
  };
  const calculateAdvancePaidSrc = () => {
    let sum = 0;
    if (alltradeDetail != null) {
      if (alltradeDetail?.pmtHistory.length > 0) {
        for (let item of alltradeDetail?.pmtHistory) {
          if (
            item.pmtType.toUpperCase() == "ADVANCE" &&
            item.inOutWard == "OUTWARD"
          ) {
            sum += item.amount;
          }
        }
      }
    }
    return sum;
  };
  const addDays = (date: any, days: any) => {
    var dateVal = date ? new Date(date) : new Date();
    var result = "";
    dateVal.setDate(dateVal.getDate() + parseInt(days));
    result = moment(dateVal).format("DD-MMM-YYYY");
    return result;
  };
  const days_diff = (dDate: any) => {
    var date1 = moment("2022-10-30");
    var date2 = moment("2022-12-30");
    var days = date1.diff(date2, "days");
    //  //calculate time difference
    // var time_difference = currentDate.getTime() - dueDate.getTime();

    // //  //calculate days difference by dividing total milliseconds in a day
    // var days_difference = time_difference / (1000 * 60 * 60 * 24);
    return days + "days";
  };
  const [arrivalIInfo, setArrivalIInfo] = useState<{
    freightAmt: number | "";
    date: string;
    pattiAmt: number | "";
    comm: number | "";
    discount: number | "";
    lbrCharges: number | "";
    misc: number | "";
    totalPayable: number | "";
    payment: number | "";
    paidDate: string;
    arlTime: string;
    inwardPmt: number;
    balPayable: number;
    invAmt: number | "";
    freightPaid: number | "";
    balFreightAmt: number | "";
    weightAtSrc: number | "";
    destWeight: number | "";
    destWastage: number | "";
    netWeight: number | "";
    arlComments: string;
    pattiDate: string;
    pattiComments: string;
    commPerVal: number | "";
    finalPayable: number;
    srcPf: number | "";
    paymentMode: string | number;
    paymentComments: string;
    moistureLoss: number;
    avgRate: number;
    advancePaid: number;
    platformFeeVal: number | "";
    platformFeeTotal: number | "";
    delayedChargesVal: number | "";
    delayedChargesPer: number | "";
    paymentTerms: number | "";
    dueDate: string;
    destRate: number | "";
    finalInvoice: number | "";
    rate: number | "";
    destPf: number | "";
    destDis: number | "";
    srcDis: number | "";
    destPfPer: number | "";
    srcPfPer: number | "";
    destPmtTerm: string;
    srcPmtTerm: string;
    delayedDays: string;
    totalReceivable: number | "";
    advanceRcvd: number;
    destDisc: number | "";
    srcDisc: number | "";
    destVehNum: string | "";
    advanceLimit: number | "";
    cmdityCost: number | "";
  }>({
    freightAmt: tradeDetails?.freightAmt!,
    date:
      tradeDetails?.arlDate == null
        ? moment(new Date()).format("YYYY-MM-DD")
        : tradeDetails?.arlDate == ""
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date(tradeDetails?.arlDate!)).format("YYYY-MM-DD"),
    pattiAmt: tradeDetails?.pattiAmt || 0,
    comm: tradeDetails?.comm || 0,
    discount: tradeDetails?.discount || 0,
    lbrCharges: tradeDetails?.lbrCharges || 0,
    misc: tradeDetails?.misc || 0,
    totalPayable: tradeDetails?.totalPayable!,
    payment: paymentUpdateStatus
      ? paymentHistoryObj?.amount || 0
      : tradeDetails?.payment || 0,
    paidDate: moment(new Date()).format("YYYY-MM-DD"),
    arlTime:
      tradeDetails?.arlTime == "" || tradeDetails?.arlTime == null
        ? (new Date().getHours() < 10 ? "0" : "") +
          new Date().getHours() +
          ":" +
          (new Date().getMinutes() < 10 ? "0" : "") +
          new Date().getMinutes() +
          ":" +
          (new Date().getSeconds() < 10 ? "0" : "") +
          new Date().getSeconds()
        : tradeDetails?.arlTime,
    inwardPmt: tradeDetails?.inwardPmt!,
    balPayable:
      Number(tradeDetails?.totalPayable) - Number(tradeDetails?.inwardPmt),
    invAmt: tradeDetails?.invAmt!,
    freightPaid: tradeDetails?.freightPaid || 0,
    // ? tradeDetails?.freightPaid!
    // : Number(tradeDetails?.freightAmt) - Number(tradeDetails?.freightAdv),
    balFreightAmt: tradeDetails?.freightAmt!,
    weightAtSrc:
      (tradeDetails?.lineItems.length != 0 &&
        tradeDetails?.lineItems[0].weight) ||
      0,
    destWeight:
      (tradeDetails?.lineItems.length != 0 &&
        tradeDetails?.lineItems[0].destWeight) ||
      0,
    destWastage:
      (tradeDetails?.lineItems.length != 0 &&
        tradeDetails?.lineItems[0].addWastage) ||
      0,
    netWeight:
      (tradeDetails?.lineItems.length != 0 &&
        Number(tradeDetails?.lineItems[0].destWeight) > 0 &&
        Number(tradeDetails?.lineItems[0].destWeight) -
          Number(tradeDetails?.lineItems[0].addWastage)) ||
      0,
    arlComments: tradeDetails?.arlComments! || "",
    pattiDate:
      tradeDetails?.pattiDate == "" || tradeDetails?.pattiDate == null
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date(tradeDetails?.pattiDate!)).format("YYYY-MM-DD"),
    pattiComments: tradeDetails?.pattiComments! || "",
    commPerVal: tradeDetails?.commPerVal! || 0,
    finalPayable: tradeDetails?.finalPayable! || 0,
    srcPf: tradeDetails?.srcPf! || 0,
    paymentMode: paymentUpdateStatus
      ? paymentHistoryObj?.pmtMode || ""
      : tradeDetails?.paymentMode! || "",
    paymentComments: paymentUpdateStatus
      ? paymentHistoryObj?.comments || ""
      : tradeDetails?.paymentComments! || "",
    moistureLoss: tradeDetails?.moistureLoss! || 0,
    avgRate: tradeDetails?.avgRate || 0,
    advancePaid: tradeDetails?.advancePaid || 0,
    advanceRcvd: tradeDetails?.advanceRcvd || 0,
    platformFeeVal: tradeDetails?.platformFeeVal || 0,
    platformFeeTotal: tradeDetails?.platformFeeTotal || 0,
    delayedChargesVal: tradeDetails?.delayedChargesVal || 0,
    delayedChargesPer: tradeDetails?.delayedChargesPer || 0,
    paymentTerms: tradeDetails?.paymentTerms || 0,

    destRate:
      (tradeDetails?.lineItems.length != 0 &&
        tradeDetails?.lineItems[0].destRate) ||
      0,
    finalInvoice: tradeDetails?.invAmt || 0,
    rate:
      (tradeDetails?.lineItems.length != 0 &&
        tradeDetails?.lineItems[0].rate) ||
      0,
    destPf: tradeDetails?.destPf || 0,
    destDis: tradeDetails?.destDis || 0,
    srcDis: tradeDetails?.srcDis || 0,
    destPfPer: tradeDetails?.destPfPer || 0,
    srcPfPer: tradeDetails?.srcPfPer || 0,
    destPmtTerm: tradeDetails?.destPmtTerm || "7",
    srcPmtTerm: tradeDetails?.srcPmtTerm || "7",
    dueDate: addDays(
      tradeDetails?.arlDate,
      typeFrom == "DEST"
        ? tradeDetails?.destPmtTerm || "7"
        : tradeDetails?.srcPmtTerm || "7"
    ),
    delayedDays: days_diff(tradeDetails?.dueDate) || "-",
    totalReceivable: tradeDetails?.totalReceivable!,
    destDisc: tradeDetails?.destDisc || 0,
    srcDisc: tradeDetails?.srcDisc || 0,
    destVehNum: tradeDetails?.destVehNum || "",
    advanceLimit: tradeDetails?.advanceLimit || 0,
    cmdityCost: tradeDetails?.cmdityCost || 0,
  });
  console.log(tradeDetails?.reqAdv, "tradeDetails?.reqAdv");
  const [reqAdvInfo, setReqAdvInfo] = useState<{
    advLimit: number | "";
    requestAdv: number | "";
    advDate: string;
    comments: string;
    advDisb: number;
    advApproved: number;
    disbDate: string;
    status: number;
    srcOtp: string;
  }>({
    advLimit: tradeDetails?.reqAdv?.advLimit || 0,
    requestAdv: tradeDetails?.reqAdv?.requestAdv || 0,
    advDate:
      tradeDetails?.reqAdv?.advDate == "" ||
      tradeDetails?.reqAdv?.advDate == null
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(new Date(tradeDetails?.reqAdv?.advDate!)).format("YYYY-MM-DD"),
    comments: tradeDetails?.reqAdv?.comments || "",
    advDisb: tradeDetails?.reqAdv?.advDisb || 0,
    advApproved: tradeDetails?.reqAdv?.advApproved || 0,
    disbDate: tradeDetails?.reqAdv?.disbDate || "",
    status:
      tradeDetails?.reqAdv != null
        ? tradeDetails?.reqAdv?.status == 1
          ? 2
          : tradeDetails?.reqAdv?.status || 1
        : 1,
    srcOtp: "",
  });
  useEffect(() => {
    console.log(alltradeDetail, "alltradeDetail");
    if (alltradeDetail == null) {
      setAllTradeDetail(tradeDetails);
      setAllTradeDetail({
        ...tradeDetails!,
        pattiAmt: arrivalIInfo.pattiAmt,
        comm: arrivalIInfo?.comm,
        discount: arrivalIInfo?.discount,
        destDisc: arrivalIInfo?.destDisc || 0,
        srcDisc: arrivalIInfo?.srcDisc || 0,
        lbrCharges: arrivalIInfo?.lbrCharges,
        misc: arrivalIInfo?.misc,
        totalPayable: arrivalIInfo?.totalPayable,
        payment: arrivalIInfo?.payment,
        arlDate: arrivalIInfo?.date,
        arlTime: arrivalIInfo?.arlTime,
        inwardPmt: arrivalIInfo?.inwardPmt || 0,
        balPayable:
          Number(arrivalIInfo?.totalPayable) -
            Number(arrivalIInfo?.inwardPmt) || 0,
        invAmt: arrivalIInfo?.invAmt,
        tradeMode: tradeDetails?.tradeMode!,
        freightPaid: tradeDetails?.freightPaid || 0,
        // ? tradeDetails?.freightPaid!
        // : Number(tradeDetails?.freightAmt) -
        //   Number(tradeDetails?.freightAdv),
        weightAtSrc:
          (tradeDetails?.lineItems.length != 0 &&
            tradeDetails?.lineItems[0].weight) ||
          0,
        destWeight: arrivalIInfo?.destWeight!,
        destWastage: arrivalIInfo?.destWastage!,
        netWeight:
          (Number(arrivalIInfo?.destWeight) > 0 &&
            Number(arrivalIInfo?.destWeight) -
              Number(arrivalIInfo?.destWastage)) ||
          0,
        arlComments: arrivalIInfo?.arlComments!,
        pattiComments: arrivalIInfo?.pattiComments!,
        commPerVal: arrivalIInfo?.commPerVal,
        finalPayable: arrivalIInfo.finalPayable,
        srcPf: arrivalIInfo?.srcPf,
        paymentMode: arrivalIInfo?.paymentMode!,
        paymentComments: arrivalIInfo?.paymentComments!,
        moistureLoss:
          Number(arrivalIInfo?.weightAtSrc) - Number(arrivalIInfo?.destWeight),
        avgRate: arrivalIInfo?.avgRate,
        pmtHistory: tradeDetails?.pmtHistory || [],
        advancePaid: arrivalIInfo?.advancePaid,
        advanceRcvd: arrivalIInfo?.advanceRcvd,
        platformFeeVal: arrivalIInfo?.platformFeeVal || 0,
        platformFeeTotal: arrivalIInfo?.platformFeeTotal || 0,
        delayedChargesVal: arrivalIInfo?.delayedChargesVal || 0,
        delayedChargesPer: arrivalIInfo?.delayedChargesPer || 0,
        paymentTerms: arrivalIInfo?.paymentTerms || 0,
        destPf: arrivalIInfo?.destPf,
        destDis: arrivalIInfo?.destDis,
        srcDis: arrivalIInfo?.srcDis,
        destPfPer: arrivalIInfo?.destPfPer,
        srcPfPer: arrivalIInfo?.srcPfPer,
        destRate: arrivalIInfo?.destRate || 0,
        rate: arrivalIInfo?.rate || 0,
        destPmtTerm: arrivalIInfo?.destPmtTerm || "",
        srcPmtTerm: arrivalIInfo?.srcPmtTerm || "7",
        dueDate: arrivalIInfo?.dueDate,
        delayedDays: days_diff(arrivalIInfo?.dueDate) || "-",
        totalReceivable: arrivalIInfo?.totalReceivable || 0,
        srcPmtHistory: tradeDetails?.srcPmtHistory || [],
        destPmtHistory: tradeDetails?.destPmtHistory || [],
        destVehNum: tradeDetails?.destVehNum || "",
        advanceLimit: tradeDetails?.advanceLimit || 0,
        cmdityCost: arrivalIInfo?.cmdityCost,
      });
    } else {
      setAllTradeDetail({
        ...tradeDetails!,
        destPmtTerm: arrivalIInfo?.destPmtTerm || "",
        srcPmtTerm: arrivalIInfo?.srcPmtTerm || "7",
        dueDate: arrivalIInfo?.dueDate,
        delayedDays: days_diff(arrivalIInfo?.dueDate) || "-",
        srcPmtHistory: tradeDetails?.srcPmtHistory || [],
        destPmtHistory: tradeDetails?.destPmtHistory || [],
        totalReceivable: tradeDetails?.totalReceivable || 0,
        destVehNum: tradeDetails?.destVehNum || "",
        arlDate:
          tradeDetails?.arlDate == null
            ? moment(new Date()).format("YYYY-MM-DD")
            : tradeDetails?.arlDate == ""
            ? moment(new Date()).format("YYYY-MM-DD")
            : moment(new Date(tradeDetails?.arlDate!)).format("YYYY-MM-DD"),
      });
    }
  }, [tradeDetails]);
  useEffect(() => {
    if (alltradeDetail != null) {
      console.log(
        arrivalIInfo?.invAmt,
        alltradeDetail?.destRate,
        arrivalIInfo?.freightPaid,
        "hui"
      );
      var invAmt =
        Number(arrivalIInfo?.cmdityCost) + Number(arrivalIInfo?.freightAmt);
      var payable =
        // Number(arrivalIInfo?.freightPaid) != 0
        // ?
        invAmt -
        (Number(arrivalIInfo?.freightPaid) +
          calculateAdvancePaid() +
          (Number(arrivalIInfo?.destDisc) || 0));
      //     : Number(arrivalIInfo?.invAmt) -
      //       (Number(arrivalIInfo?.freightAmt) +
      //         calculateAdvancePaid() +
      //         (Number(arrivalIInfo?.destDisc) || 0));
      var receivable =
        // Number(arrivalIInfo?.freightPaid) != 0
        // ?
        invAmt -
        (Number(arrivalIInfo?.freightPaid) +
          calculateAdvancePaidSrc() +
          (Number(arrivalIInfo?.srcDisc) || 0));
      //     : Number(arrivalIInfo?.invAmt) -
      //       (Number(arrivalIInfo?.freightAmt) +
      //         calculateAdvancePaid() +
      //         (Number(arrivalIInfo?.destDisc) || 0));
      setAllTradeDetail({
        ...alltradeDetail,
        freightPaid: arrivalIInfo?.freightPaid || 0,
        // ? arrivalIInfo?.freightPaid
        // : Number(alltradeDetail?.freightAmt) -
        //   Number(alltradeDetail?.freightAdv),
        arlDate: arrivalIInfo.date,
        arlTime: arrivalIInfo.arlTime,
        balFreightAmt:
          Number(alltradeDetail?.freightAmt) - Number(arrivalIInfo.freightPaid),
        weightAtSrc:
          (tradeDetails?.lineItems.length != 0 &&
            alltradeDetail?.lineItems[0].weight) ||
          0,
        destWeight: arrivalIInfo?.destWeight!,
        destWastage: arrivalIInfo?.destWastage!,
        netWeight:
          Number(arrivalIInfo.destWastage) > 0 &&
          Number(arrivalIInfo?.destWeight) > 0
            ? parseFloat(
                (
                  Number(arrivalIInfo?.destWeight) -
                  Number(arrivalIInfo.destWastage)
                ).toFixed(2)
              )
            : Number(arrivalIInfo?.netWeight),
        arlComments: arrivalIInfo?.arlComments!,
        moistureLoss:
          Number(alltradeDetail?.weightAtSrc) - Number(arrivalIInfo.destWeight),
        destRate: arrivalIInfo?.destRate,
        cmdityCost: arrivalIInfo?.cmdityCost,
        // invAmt: arrivalIInfo?.invAmt,
        invAmt:
          Number(arrivalIInfo?.cmdityCost) + Number(arrivalIInfo?.freightAmt),
        rate: arrivalIInfo?.rate,
        totalPayable:
          alltradeDetail?.tradeMode == "ORDER"
            ? payable > 0
              ? payable
              : 0
            : alltradeDetail?.totalPayable,
        // totalPayable:
        //   alltradeDetail?.tradeMode == "ORDER"
        //     ? Number(arrivalIInfo?.invAmt) -
        //       (Number(arrivalIInfo?.freightPaid) + calculateAdvancePaid())
        //     : // : Number(arrivalIInfo?.invAmt) -
        //       //   (Number(arrivalIInfo?.freightAmt) + calculateAdvancePaid())
        //       alltradeDetail?.totalPayable,
        totalReceivable:
          alltradeDetail?.tradeMode == "ORDER"
            ? receivable > 0
              ? receivable
              : 0
            : // : Number(arrivalIInfo?.invAmt) -
              //   (Number(arrivalIInfo?.freightAmt) + calculateAdvancePaidSrc())
              alltradeDetail?.totalReceivable,
        destVehNum: arrivalIInfo?.destVehNum,
      });
      // console.log(
      //   alltradeDetail?.totalPayable,
      //   alltradeDetail?.tradeMode,
      //   alltradeDetail?.totalReceivable,
      //   arrivalIInfo?.invAmt,
      //   arrivalIInfo?.freightPaid
      // );
    }
  }, [
    arrivalIInfo.date,
    arrivalIInfo.freightPaid,
    arrivalIInfo.arlTime,
    arrivalIInfo?.destWeight,
    arrivalIInfo?.netWeight,
    arrivalIInfo?.destWastage,
    arrivalIInfo?.arlComments,
    arrivalIInfo?.destRate,
    // arrivalIInfo?.invAmt,
    arrivalIInfo?.rate,
    arrivalIInfo?.destVehNum,
    arrivalIInfo?.cmdityCost,
  ]);

  useEffect(() => {
    if (alltradeDetail != null) {
      let payable =
        alltradeDetail?.tradeMode == "ORDER"
          ? Number(arrivalIInfo?.invAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              (Number(arrivalIInfo?.destDisc) || 0) +
              calculateAdvancePaid())
          : Number(arrivalIInfo?.pattiAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              Number(arrivalIInfo?.comm) +
              Number(arrivalIInfo?.lbrCharges) +
              calculateAdvancePaid() +
              Number(arrivalIInfo?.misc));
      let receiveable =
        alltradeDetail?.tradeMode == "ORDER"
          ? Number(arrivalIInfo?.invAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              (Number(arrivalIInfo?.srcDisc) || 0) +
              calculateAdvancePaidSrc())
          : Number(arrivalIInfo?.pattiAmt) -
            (Number(arrivalIInfo?.freightPaid) +
              Number(arrivalIInfo?.comm) +
              Number(arrivalIInfo?.lbrCharges) +
              calculateAdvancePaidSrc() +
              Number(arrivalIInfo?.misc));
      console.log(payable);
      setAllTradeDetail({
        ...alltradeDetail,
        pattiDate: arrivalIInfo.pattiDate,
        pattiAmt: arrivalIInfo.pattiAmt,
        freightPaid: arrivalIInfo.freightPaid,
        comm: arrivalIInfo.comm || 0,
        lbrCharges: arrivalIInfo.lbrCharges,
        discount: arrivalIInfo.discount,
        destDisc: arrivalIInfo?.destDisc || 0,
        srcDisc: arrivalIInfo?.srcDisc || 0,
        misc: arrivalIInfo.misc,
        payment: arrivalIInfo.payment,
        totalPayable:
          alltradeDetail?.tradeMode == "ORDER"
            ? Number(arrivalIInfo?.invAmt) -
              (Number(arrivalIInfo?.freightPaid) +
                (Number(arrivalIInfo?.destDisc) || 0) +
                calculateAdvancePaid())
            : Number(arrivalIInfo?.pattiAmt) -
              (Number(arrivalIInfo?.freightPaid) +
                Number(arrivalIInfo?.comm) +
                Number(arrivalIInfo?.lbrCharges) +
                calculateAdvancePaid() +
                Number(arrivalIInfo?.misc)),
        totalReceivable: receiveable,
        pattiComments: arrivalIInfo.pattiComments,
        commPerVal: arrivalIInfo.commPerVal,
        finalPayable:
          typeFrom == "DEST"
            ? Number(payable) +
              Number(arrivalIInfo?.destPf) -
              Number(arrivalIInfo?.destDis)
            : Number(receiveable) -
              Number(arrivalIInfo?.srcPf) +
              Number(arrivalIInfo?.srcDis),
        avgRate: arrivalIInfo?.avgRate,
        advancePaid: calculateAdvancePaid(),
        destPf: arrivalIInfo?.destPf,
        destDis: arrivalIInfo?.destDis,
        srcDis: arrivalIInfo?.srcDis,
        destPfPer: arrivalIInfo?.destPfPer,
        srcPfPer: arrivalIInfo?.srcPfPer,
        srcPf: arrivalIInfo?.srcPf,
        advanceRcvd: arrivalIInfo?.advanceRcvd,
        invAmt: arrivalIInfo.invAmt,
      });
      console.log(
        alltradeDetail?.totalPayable,
        alltradeDetail?.totalPayable,
        payable,
        "alltradeDetail?.totalPayable"
      );
    }
  }, [
    arrivalIInfo.comm,
    arrivalIInfo.pattiAmt,
    arrivalIInfo.lbrCharges,
    arrivalIInfo.misc,
    arrivalIInfo.discount,
    arrivalIInfo?.destDisc,
    arrivalIInfo?.srcDisc,
    arrivalIInfo.payment,
    // arrivalIInfo.freightPaid,
    arrivalIInfo.pattiDate,
    arrivalIInfo.pattiComments,
    arrivalIInfo.commPerVal,
    // arrivalIInfo?.totalPayable,
    arrivalIInfo?.srcPf,
    arrivalIInfo?.destPf,
    arrivalIInfo?.destDis,
    arrivalIInfo?.srcDis,
    arrivalIInfo?.destPfPer,
    arrivalIInfo?.srcPfPer,
  ]);

  useEffect(() => {
    if (alltradeDetail != null) {
      setAllTradeDetail({
        ...alltradeDetail,
        payment: arrivalIInfo.payment,
        paidDate: arrivalIInfo.paidDate,
        balPayable:
          Number(arrivalIInfo.totalPayable) - alltradeDetail?.inwardPmt,
        paymentMode: arrivalIInfo?.paymentMode,
        paymentComments: arrivalIInfo?.paymentComments,
        platformFeeVal: arrivalIInfo?.platformFeeVal || 0,
        platformFeeTotal: arrivalIInfo?.platformFeeTotal || 0,
        delayedChargesVal: arrivalIInfo?.delayedChargesVal || 0,
        delayedChargesPer: arrivalIInfo?.delayedChargesPer || 0,
        paymentTerms: arrivalIInfo?.paymentTerms || 0,
        destPmtTerm: arrivalIInfo?.destPmtTerm || "",
        srcPmtTerm: arrivalIInfo?.srcPmtTerm || "",
        dueDate: addDays(
          alltradeDetail?.arlDate,
          typeFrom == "DEST"
            ? arrivalIInfo?.destPmtTerm || ""
            : arrivalIInfo?.srcPmtTerm || ""
        ),
        delayedDays: days_diff(alltradeDetail?.dueDate) || "-",
        advanceLimit: arrivalIInfo?.advanceLimit,
        // status:reqAdvInfo?.
      });
    }
  }, [
    arrivalIInfo.payment,
    arrivalIInfo.paidDate,
    arrivalIInfo?.paymentMode,
    arrivalIInfo?.paymentComments,
    arrivalIInfo?.platformFeeVal,
    arrivalIInfo?.platformFeeTotal,
    arrivalIInfo?.delayedChargesVal,
    arrivalIInfo?.delayedChargesPer,
    arrivalIInfo?.paymentTerms,
    arrivalIInfo?.destPmtTerm,
    arrivalIInfo?.srcPmtTerm,
    arrivalIInfo?.advanceLimit,
  ]);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    if (alltradeDetail != null) {
      setArrivalIInfo({
        ...arrivalIInfo,
        date: date,
        netWeight: alltradeDetail.netWeight,
      });
    }
    setCalenderOpen(false);
  };
  const handlePaidDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    if (alltradeDetail != null) {
      setArrivalIInfo({ ...arrivalIInfo, paidDate: date });
    }
    setCalenderOpen(false);
  };
  const handlePattiDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    if (alltradeDetail != null) {
      setArrivalIInfo({ ...arrivalIInfo, pattiDate: date });
    }
    setCalenderOpen(false);
  };
  const handleTimeSelection = (time: string) => {
    if (alltradeDetail != null) {
      setArrivalIInfo({
        ...arrivalIInfo,
        arlTime: time,
        netWeight: alltradeDetail.netWeight,
      });
    }
  };
  const onUploadReceiptImage = async (
    type: "arrival" | "patti" | "payment" | "arrivalWight",
    file: any
  ) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("traderId", traderId.toString());
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/trade-portal/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];
      if (type === "arrival") {
        setOtherDocuments([{ docType: "pdf", url: url }, ...arrivalDocuments]);
      } else if (type === "patti") {
        setPattiDocuments([{ docType: "pdf", url: url }, ...pattiDocuments]);
      } else if (type === "payment") {
        setPaymentDocuments([
          { docType: "pdf", url: url },
          ...paymentDocuments,
        ]);
      } else if (type === "arrivalWight") {
        setArrivalWeightDocuments([
          { docType: "pdf", url: url },
          ...arrivalWeightDocuments,
        ]);
      }
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/trade-portal/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];
          if (type === "arrival") {
            setOtherDocuments([
              ...arrivalDocuments,
              { docType: "image", url: url },
            ]);
          } else if (type === "patti") {
            setPattiDocuments([
              ...pattiDocuments,
              { docType: "image", url: url },
            ]);
          } else if (type === "payment") {
            setPaymentDocuments([
              ...paymentDocuments,
              { docType: "image", url: url },
            ]);
          } else if (type === "arrivalWight") {
            setArrivalWeightDocuments([
              ...arrivalWeightDocuments,
              { docType: "image", url: url },
            ]);
          }
        },
      });
    }
  };

  const onRemoveReceiptImage = (
    type: "arrival" | "patti" | "payment" | "arrivalWight",
    index: number,
    isExist: boolean = false
  ) => {
    if (type == "arrival") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.arrivalDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({ ...alltradeDetail, arrivalDocs: invoices });
      } else {
        let newtapalInvoice = [...arrivalDocuments];
        newtapalInvoice.splice(index, 1);
        setOtherDocuments(newtapalInvoice);
      }
    } else if (type == "patti") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.pattiDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({ ...alltradeDetail, pattiDocs: invoices });
      } else {
        let newtapalInvoice = [...pattiDocuments];
        newtapalInvoice.splice(index, 1);
        setPattiDocuments(newtapalInvoice);
      }
    } else if (type == "payment") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.paymentDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({ ...alltradeDetail, paymentDocs: invoices });
      } else {
        let newtapalInvoice = [...paymentDocuments];
        newtapalInvoice.splice(index, 1);
        setPaymentDocuments(newtapalInvoice);
      }
    } else if (type == "arrivalWight") {
      if (isExist === true && alltradeDetail !== null) {
        let invoices = [...alltradeDetail.arrivalWeightbridgeDocs];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setAllTradeDetail({
          ...alltradeDetail,
          arrivalWeightbridgeDocs: invoices,
        });
      } else {
        let newtapalInvoice = [...arrivalWeightDocuments];
        newtapalInvoice.splice(index, 1);
        setArrivalWeightDocuments(newtapalInvoice);
      }
    } else {
      let newtapalInvoice = [...arrivalWeightDocuments];
      newtapalInvoice.splice(index, 1);
      setArrivalWeightDocuments(newtapalInvoice);
    }
  };

  const confrirmArrival = async () => {
    let paidAmmtVal =
      Number(alltradeDetail?.payment) + alltradeDetail?.inwardPmt!;
    let arrrivalDate =
      type == "ARRIVAL"
        ? new Date(alltradeDetail?.arlDate!)
        : type == "PATTI"
        ? new Date(alltradeDetail?.pattiDate!)
        : new Date(alltradeDetail?.paidDate!);

    let arrString =
      type == "ARRIVAL" ? "Arrival" : type == "PATTI" ? "Patti" : "Paid on";
    let tradeDate = moment(alltradeDetail?.date).format("YYYY-MM-DD");
    let date3 = new Date(tradeDate);
    if (arrrivalDate.getTime() < date3.getTime() && type == "ARRIVAL") {
      toast.error(`${arrString}s date can not be prior to the dispatch date.`);

      return;
    }

    var valueDay = isArrivalDateStatus(alltradeDetail?.arlDate, tradeDate);
    if (!valueDay) {
      toast.error(
        `${arrString} Date cannot be beyond 7 days from Trade Order created Date.`,
        {
          toastId: "error6",
        }
      );
      return;
    }

    // if (
    //   alltradeDetail?.freightPaid == 0 &&
    //   (type == "ARRIVAL" || type == "PATTI")
    // ) {
    //   toast.error("Please enter Freight Paid Amount");
    //   return;
    // }
    if (alltradeDetail?.destWeight == 0 && type == "ARRIVAL") {
      toast.error("Please enter Weight ");
      return;
    }
    // if (
    //   alltradeDetail?.destWeight! > alltradeDetail?.weightAtSrc! &&
    //   type == "ARRIVAL"
    // ) {
    //   toast.error("Weight at Destination should be less than Weight at Source");
    //   return;
    // }
    if (alltradeDetail?.netWeight == 0 && type == "ARRIVAL") {
      toast.error("Please enter Net Weight ");
      return;
    }
    if (
      alltradeDetail?.destWastage! > alltradeDetail?.destWeight! &&
      type == "ARRIVAL"
    ) {
      toast.error("Wastage should be less than Weight at Destination");
      return;
    }
    if (
      alltradeDetail?.netWeight! > alltradeDetail?.destWeight! &&
      type == "ARRIVAL"
    ) {
      toast.error("Net Weight should be less than Weight at Destination");
      return;
    }
    if (
      alltradeDetail?.destRate == 0 &&
      type == "ARRIVAL" &&
      alltradeDetail?.tradeMode == "ORDER"
    ) {
      toast.error("Please enter Final Rate ");
      return;
    }
    // if (alltradeDetail?.destVehNum == "" && type == "ARRIVAL") {
    //   toast.error("Please enter Truck Number ");
    //   return;
    // }
    if (alltradeDetail?.pattiAmt == 0 && type == "PATTI") {
      toast.error("Please enter Sales Amount");
      return;
    }
    if (
      (type == "PATTI" || type == "ARRIVAL") &&
      alltradeDetail?.freightPaid != 0 &&
      alltradeDetail?.freightAmt != 0
    ) {
      if (
        Number(alltradeDetail?.freightPaid) > Number(alltradeDetail?.freightAmt)
      ) {
        toast.error("Entered Freight Paid less than the Freight Amount");
        return;
      }
    }

    if (
      Number(alltradeDetail?.pattiAmt) < Number(alltradeDetail?.freightPaid) &&
      type == "PATTI"
    ) {
      toast.error("Entered Sales Amount not less than the Freight amount");
      return;
    }
    // if (type == "PATTI" && alltradeDetail?.comm == 0) {
    //   toast.error("Please enter Comission");
    //   return;
    // }
    if (
      type == "PATTI" &&
      Number(alltradeDetail?.pattiAmt) < Number(alltradeDetail?.advancePaid)
    ) {
      toast.error("Entered Sales Amount not less than the Advance Paid");
      return;
    }
    if (arrrivalDate.getTime() < date3.getTime() && type == "PATTI") {
      toast.error(`${arrString} date can not be prior to the dispatch date.`);
      return;
    }
    // if (type == "PATTI" && pattiDocuments.length === 0) {
    //   if (alltradeDetail?.pattiDocs.length === 0) {
    //     toast.error(
    //       "Please upload patti image/pdf or take a picture of patti."
    //     );
    //     return;
    //   }
    // }
    // if (type == "PATTI" && alltradeDetail?.lbrCharges == 0) {
    //   toast.error("Please enter Comission");
    //   return;
    // }
    // if (arrrivalDate.getTime() < date3.getTime() && type == "PAYMENT") {
    //   toast.error(`${arrString} date can not be prior to the dispatch date.`);
    //   return;
    // }
    // if (type == "PAYMENT" && alltradeDetail?.payment == 0) {
    //   toast.error("Please enter Amount");
    //   return;
    // }
    // if (type == "PAYMENT" && paymentDocuments.length === 0) {
    //   if (alltradeDetail?.paymentDocs.length === 0) {
    //     toast.error("Please upload atleast one payment receipt");
    //     return;
    //   }
    // }
    // if (
    //   type == "PAYMENT" &&
    //   alltradeDetail?.totalPayable != 0 &&
    //   alltradeDetail?.payment
    // ) {
    //   if (alltradeDetail?.payment > alltradeDetail?.totalPayable) {
    //     toast.error("Entered Amount less than Total Paybles");
    //     return;
    //   }
    // }
    // if (
    //   type == "PAYMENT" &&
    //   alltradeDetail?.totalPayable != 0 &&
    //   alltradeDetail?.balPayable != 0 &&
    //   alltradeDetail?.payment
    // ) {
    //   if (alltradeDetail?.payment > alltradeDetail?.balPayable) {
    //     toast.error("Please enter the amount less or equal to balance payable");
    //     return;
    //   }
    // }
    // if (type == "PAYMENT" && alltradeDetail?.paymentMode == "") {
    //   toast.error("Please Select payment mode");
    //   return;
    // }
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        addedBy: number;
        type:
          | "tapal"
          | "receipt"
          | "other"
          | "arrival"
          | "patti"
          | "payment"
          | "arrivalWight";
        url: string;
      }> = [];
      let arrivalImages = imgs;
      let pattiImages = imgs;
      let paymentImages = imgs;
      let arrivalWeightImages = imgs;
      if (arrivalDocuments.length > 0) {
        for (let file of arrivalDocuments) {
          arrivalImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "arrival",
            url: file.url,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.arrivalDocs.length > 0) {
            for (let file of alltradeDetail?.arrivalDocs) {
              if (file.status == 0) {
                arrivalImages.push({
                  ...file,
                  addedBy: traderId,
                });
              }
            }
          }
        }
      }
      if (pattiDocuments.length > 0) {
        for (let file of pattiDocuments) {
          pattiImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "patti",
            url: file.url,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.pattiDocs.length > 0) {
            for (let file of alltradeDetail?.pattiDocs) {
              if (file.status == 0) {
                pattiImages.push({
                  ...file,
                  addedBy: traderId,
                });
              }
            }
          }
        }
      }
      if (paymentDocuments.length > 0) {
        for (let file of paymentDocuments) {
          paymentImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "payment",
            url: file.url,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.paymentDocs.length > 0) {
            for (let file of alltradeDetail?.paymentDocs) {
              if (file.status == 0) {
                paymentImages.push({
                  ...file,
                  addedBy: traderId,
                });
              }
            }
          }
        }
      }
      // if (type == "ARRIVAL") {
      //   if (arrivalWeightDocuments.length > 0) {
      //     for (let file of arrivalWeightDocuments) {
      //       arrivalWeightImages.push({
      //         id: 0,
      //         imgFrom: "DEST_TRADER",
      //         reqId: 0,
      //         status: 1,
      //         addedBy: traderId || 0,
      //         type: "arrivalWight",
      //         url: file.url,
      //       });
      //     }
      //   } else {
      //     if (alltradeDetail != null && mode) {
      //       if (alltradeDetail?.arrivalWeightbridgeDocs.length > 0) {
      //         for (let file of alltradeDetail?.arrivalWeightbridgeDocs) {
      //           if (file.status == 0) {
      //             arrivalImages.push({
      //               ...file,
      //               addedBy: traderId,
      //             });
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      let postBody = {};
      if (type == "ARRIVAL") {
        postBody = {
          adminId: adminId,
          arlConf: {
            arlDate: alltradeDetail?.arlDate,
            freightPaid: alltradeDetail?.freightPaid,
            images: arrivalImages,
            arlTime: alltradeDetail?.arlTime,
            comments: alltradeDetail?.arlComments || "UPDATED",
            addWastage: alltradeDetail?.destWastage,
            destWeight: alltradeDetail?.destWeight,
            // arlTime:18:1:50
            tradeId: alltradeDetail?.tradeId,
            tradeStatus:
              mode && tradeDetails?.lineItems[0].destWeight
                ? alltradeDetail?.tradeStatus
                : "ARL_CONFIRMED",
            destRate: alltradeDetail?.destRate,
            invAmt: alltradeDetail?.invAmt,
            totalPayable: alltradeDetail?.totalPayable,
            totalReceivable: alltradeDetail?.totalReceivable,
            destVehNum: alltradeDetail?.destVehNum,
            cmdityCost:
              alltradeDetail?.tradeMode == "ORDER"
                ? alltradeDetail?.cmdityCost
                : "",
          },
          tradeId: alltradeDetail?.tradeId,
        };
      } else if (type == "PATTI" || type == "INVOICE") {
        postBody = {
          adminId: adminId,
          pattiFinal: {
            advPaid:
              typeFrom == "DEST"
                ? calculateAdvancePaid()
                : calculateAdvancePaidSrc(),
            comm: alltradeDetail?.comm,
            discount:
              typeFrom == "DEST"
                ? alltradeDetail?.destDisc
                : alltradeDetail?.srcDisc,
            freightPaid: alltradeDetail?.freightPaid,
            images: pattiImages,
            lbrCharges: alltradeDetail?.lbrCharges,
            misc: alltradeDetail?.misc,
            pattiAmt: alltradeDetail?.pattiAmt,
            totalPayable: alltradeDetail?.totalPayable,
            totalReceivable: alltradeDetail?.totalReceivable,
            tradeId: alltradeDetail?.tradeId,
            tradeStatus:
              mode && tradeDetails?.pattiAmt
                ? alltradeDetail?.tradeStatus
                : "PATTI_FINALIZED",
            pattiDate: alltradeDetail?.pattiDate,
            comments: alltradeDetail?.pattiComments || "UPDATED",
            finalPayable: alltradeDetail?.finalPayable,
            pfFee:
              typeFrom == "DEST"
                ? alltradeDetail?.destPf
                : alltradeDetail?.srcPf,
            pfFeeDisc:
              typeFrom == "DEST"
                ? alltradeDetail?.destDis
                : alltradeDetail?.srcDis,
            tradeType: alltradeDetail?.tradeMode,
            type: typeFrom,
            invAmt: alltradeDetail?.invAmt,
          },
          tradeId: alltradeDetail?.tradeId,
        };
      } else if (type == "PAYMENT") {
        dispatch(
          updateTradeDetails({
            tradeDetails: {
              ...alltradeDetail!,
              totalPayable: alltradeDetail?.totalPayable!,
              balPayable:
                Number(alltradeDetail?.totalPayable) -
                Number(alltradeDetail?.inwardPmt),
            },
          })
        );
        postBody = {
          adminId: adminId,
          pmtDtls: {
            // account: "",
            // ifsc: "HDFC0001012",
            images: paymentImages,
            delayedPmtCharges:
              typeFrom == "DEST" ? alltradeDetail?.delayedChargesVal : 0,
            tradeId: alltradeDetail?.tradeId,
            dueDate: moment(alltradeDetail?.dueDate).format("YYYY-MM-DD"),
            tradeStatus: alltradeDetail?.tradeStatus,
            comments: alltradeDetail?.paymentComments || "UPDATED",
            pmtTerms:
              typeFrom == "DEST"
                ? parseInt(alltradeDetail?.destPmtTerm!)
                : parseInt(alltradeDetail?.srcPmtTerm!),
            type: typeFrom,
            advLimit: reqAdvInfo?.advLimit,
            advApproved: reqAdvInfo?.advApproved,
            advDate: reqAdvInfo?.advDate,
            advDisb: reqAdvInfo?.advDisb,
            disbDate: reqAdvInfo?.disbDate,
            rcomments: reqAdvInfo?.comments,
            status:
              tradeDetails?.reqAdv != null
                ? reqAdvInfo?.status == 1
                  ? 2
                  : reqAdvInfo?.status || ""
                : 1,
          },
          tradeId: alltradeDetail?.tradeId,
        };
      }
      let statusVal = searchParams.get("tab") === "CLOSED" ? "CLOSED" : "OPEN";
      await handlePutCall<any>({
        URL: "admin/trade-platform/trades/metadata",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          // setEditDetail({ section: "", mode: "view" });
          // getTradeDetail();
          navigate(
            `/trade-detail/${alltradeDetail?.srcPartyId}/${alltradeDetail?.tradeId}?tab=OPEN`
          );

          closeModal(false);
          if (type == "ARRIVAL") {
            tabActive("arival_details");
          }
          if (type == "PAYMENT") {
            tabActive("payment_details");
          }
          if (type == "PATTI") {
            tabActive("pati_details");
          }
          if (type == "INVOICE") {
            tabActive("invoice_details");
          }
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {
      // toast.update(toastid, {
      //   type: "error",
      //   render: "File upload failed please try again",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
    }
  };
  const updatePayment = async () => {
    let paidAmmtVal =
      Number(alltradeDetail?.payment) + alltradeDetail?.inwardPmt!;
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        addedBy: number;
        type:
          | "tapal"
          | "receipt"
          | "other"
          | "arrival"
          | "patti"
          | "payment"
          | "arrivalWight";
        url: string;
        typeId: number;
      }> = [];
      let paymentImages = imgs;
      if (paymentDocuments.length > 0) {
        for (let file of paymentDocuments) {
          paymentImages.push({
            id: 0,
            imgFrom: "DEST_TRADER",
            reqId: 0,
            status: 1,
            addedBy: traderId || 0,
            type: "payment",
            url: file.url,
            typeId: 40,
          });
        }
      } else {
        if (alltradeDetail != null) {
          if (alltradeDetail?.paymentDocs.length > 0) {
            for (let file of alltradeDetail?.paymentDocs) {
              if (file.status == 0) {
                paymentImages.push({
                  ...file,
                  addedBy: traderId,
                  typeId: 40,
                });
              }
            }
          }
        }
      }
      let tradeDate = moment(alltradeDetail?.date).format("YYYY-MM-DD");
      let date3 = new Date(tradeDate);
      if (new Date(alltradeDetail?.paidDate!).getTime() < date3.getTime()) {
        toast.error(`Paid on date can not be prior to the dispatch date.`);
        return;
      }
      let postBody = {
        action: "UPDATE",
        amount: alltradeDetail?.payment,
        id: paymentHistoryObj?.id,
        images: paymentImages,
        pmtMode: alltradeDetail?.paymentMode,
        status: 2,
        tradeId: alltradeDetail?.tradeId,
        comments: alltradeDetail?.paymentComments,
      };

      await handlePutCall<any>({
        URL: "mandi/trade-portal/trades/payments",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          // navigate(`/orders`);
          // navigate(`/order-view/${alltradeDetail?.tradeId}`);
          window.location.reload();
          dispatch(updateTradeId({ tradeId: alltradeDetail?.tradeId! }));
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {
      // toast.update(toastid, {
      //   type: "error",
      //   render: "File upload failed please try again",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
    }
  };
  return {
    alltradeDetail,
    confrirmArrival,
    onUploadReceiptImage,
    arrivalDocuments,
    onRemoveReceiptImage,
    handleDateSelection,
    calenderOpen,
    setCalenderOpen,
    arlDate,
    setArrivalIInfo,
    arrivalIInfo,
    pattiDocuments,
    paymentDocuments,
    handlePaidDateSelection,
    // timeChange,
    // value,
    handleTimeSelection,
    arrivalWeightDocuments,
    handlePattiDateSelection,
    updatePayment,
    paymentHistoryObj,
    reqAdvInfo,
    setReqAdvInfo,
    setOtherDocuments,
    setPattiDocuments,
  };
};
export default useArrivalsView;
