import { useEffect, useState } from "react"; 
import { useSelector } from "react-redux"; 
import useAxiosClient from "./useAxiosClient"; 
import { TTrader } from "../types/index";
import { TRootState } from "../store";

const useAllTraders = (
) => {
  const { handleGetCall } = useAxiosClient();
  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));
  const [traders, setTraders] = useState<TTrader[]>([]);
  const[onRefresh, setOnRefresh] = useState(false);

  const getAllTraders = () => {
    handleGetCall<TTrader[]>(
          `admin/trade-platform/traders/adminId/${adminId}`
        ).then(({ data }) => {
          if (data) {
            setTraders(data);
          } else {
            setTraders([]);
          }
        });
      };
  

  // useEffect(() => {
  //   handleGetCall<TTrader[]>(
  //     `admin/trade-platform/traders/adminId/${adminId}`
  //   ).then(({ data }) => {
  //     if (data) {
  //       setTraders(data);
  //     } else {
  //       setTraders([]);
  //     }
  //   });
  // }, []);
  return { traders, getAllTraders, onRefresh, setOnRefresh };
};
export default useAllTraders;
