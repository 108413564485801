import { useRef, FC, useState, ChangeEvent, useEffect } from "react";
import { FaAngleDown, FaUpload, FaXmark } from "react-icons/fa6";
import { Button, FileUploader, RadioButton } from "../../components/ui";
import EmptyProfile from "../../assets/images/profile.jpg";
import { Input } from "../../components/ui";
import Popover from "../../components/ui/popover";
import timer from "../../assets/images/timer-img.png";
import tickmark from "../../assets/images/tickmark.png";
import useAxiosClient from "../../hooks/useAxiosClient";
import DocumentPreview from "../../components/commons/document-preview";
import useCustomerDocs from "../../hooks/useCustomerDocs";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as $ from "jquery";
import marketImgg from "../../assets/images/Group.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FaSearch } from "react-icons/fa";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import { IoMdCloseCircleOutline } from "react-icons/io";

const AddTrader: FC<{
  onClose: () => void;
  onRefresh?: () => void;
  mode?: "EDIT";
}> = ({ onClose, mode, onRefresh }) => {
  const { adminId, traderId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
    traderId: state.trades.traderId,
  }));

  const { handlePostCall, handlePutCall } = useAxiosClient();
  // toast messages for kyc docs
  const validateDocs = (type: string) => {
    let tDocs = traderView?.kycDocs
      ? traderView?.kycDocs?.filter(
          (doc) => doc.type.includes(type) && doc.status != 0
        ).length
      : 0;
    let kycCount = kycDocs.filter((doc) => doc.type == type).length;
    return tDocs <= 0 && kycCount <= 0;
  };

  const {
    onUploadReceiptImage,
    onRemoveReceiptImage,
    kycDocs,
    traderView,
    setTraderView,
    getTraderDetails,
    openStatus,
    selectStatus,
    selectedOption,
    ref,
    files,
    profile,
    activeButton,
    relationshipManager,
    marketDetails,
    setMarketDetails,
    setRelationshipManager,
    searchTerm,
    searchMrkt,
    traderStatus,
    setTraderStatus,
    setSearchMrkt,
    setSearchTerm,
    setActiveButton,
    setSelectedOption,
    setSelectStatus,
    setOpenStatus,
    getRmDetails,
    getMarketDetails,
    getStatusDetails,
    setFiles,
  } = useCustomerDocs({
    traderId: traderId,
    // tradeId: 0,
    // mode: false,
    // type: "kycDocs",
  });

  const onSubmit = () => {
    console.log(traderView);
    if (traderView?.tradeType === undefined || traderView?.tradeType == "") {
      toast.error("Please enter Trader Type");
      return;
    }
    if (selectedOption === "") {
      toast.error("Please enter Business Type");
      return;
    }
    if (traderView?.relMgrId == 0 || traderView?.relMgrId == undefined) {
      toast.error("Please enter Relationship Manager");
      return;
    }
    if (traderView?.tradeLimit === undefined || traderView?.tradeLimit == "") {
      toast.error("Please enter Trade Limit");
      return;
    }
    // if (traderView?.ors === undefined || traderView?.ors == "") {
    //   toast.error("Please enter ONO RISK SCORE");
    //   return;
    // }
    if (traderView?.tradeName === undefined || traderView?.tradeName == "") {
      toast.error("Please enter Trade Name");
      return;
    }
    if (traderView?.traderName === undefined || traderView?.traderName == "") {
      toast.error("Please enter Trader Name");
      return;
    }
    // if (traderView?.tradeScode === undefined || traderView?.tradeScode == "") {
    //   toast.error("Please enter Trade Short Code");
    //   return;
    // }
    if (traderView?.marketId == 0 || traderView?.marketId == undefined) {
      toast.error("Please enter Market Name");
      return;
    }
    // if (traderView?.shopNum === undefined || traderView?.shopNum == "") {
    //   toast.error("Please enter Shop Number");
    //   return;
    // }
    if (traderView?.mobile === undefined || traderView?.mobile == "") {
      toast.error("Please enter Mobile Number");
      return;
    }
    if (traderView?.mobile.length !== 10) {
      toast.error("Please enter Valid Mobile Number");
      return;
    }
    // if (traderView?.altMobile === undefined || traderView?.altMobile == "") {
    //   toast.error("Please enter Alternate Mobile number");
    //   return;
    // }
    // if (traderView?.altMobile.length !== 10) {
    //   toast.error("Please enter Valid Alternate Mobile number");
    //   return;
    // }
    if (traderView?.pincode === undefined || traderView?.pincode == "") {
      toast.error("Please enter Pincode");
      return;
    }
    if (traderView?.pincode.length !== 6) {
      toast.error("Please enter Valid Pincode");
      return;
    }
    if (traderView?.state === undefined || traderView?.state == "") {
      toast.error("Please enter State");
      return;
    }
    if (traderView?.dist === undefined || traderView?.dist == "") {
      toast.error("Please enter District");
      return;
    }
    if (
      traderView?.addressLine === undefined ||
      traderView?.addressLine == ""
    ) {
      toast.error("Please enter Street & Village");
      return;
    }
    if (validateDocs("AADHAR")) {
      toast.error("Please Upload AADHAR");
      return;
    }
    if (validateDocs("PAN")) {
      toast.error("Please Upload PAN");
      return;
    }
    if (validateDocs("BANK_STATEMENT")) {
      toast.error("Please Upload BANK_STATEMENT");
      return;
    }
    // if (validateDocs("ITR")) {
    //   toast.error("Please Upload ITR");
    //   return;
    // }
    if (validateDocs("TRADE_LICENSE")) {
      toast.error("Please Upload TRADE_LICENSE");
      return;
    }
    // if (validateDocs("MANDI_LICENSE")) {
    //   toast.error("Please Upload MANDI_LICENSE");
    //   return;
    // }

    // creating list fo images
    const images = [];
    for (let file of kycDocs) {
      images.push({
        id: 0,
        reqId: 0,
        status: 1,
        addedBy: adminId || 0,
        type: file.type,
        url: file.url,
        category: "PERSONAL",
        platform: "TRADE",
        reviewStatus: "",
      });
    }

    //edit loki velli previous ga unna image ni delete chestam/means status==0 chestam malli new one upload chestaam adhi malli techukuntunam
    if (mode === "EDIT") {
      traderView?.kycDocs.forEach((doc) => {
        if (doc.status == 0) {
          images.push({
            id: doc.id,
            status: doc.status,
          });
        }
      });
      //edit mode lo untey put else post
      handlePutCall({
        URL: `admin/trade-platform/traders/update`,
        apiParams: {
          address: {
            addressLine: traderView?.addressLine,
            dist: traderView?.dist,
            pincode: traderView?.pincode,
            state: traderView?.state,
          },
          adminId: adminId,
          altMobile: traderView?.altMobile,
          kycDocs: images,
          marketId: traderView?.marketId,
          mobile: traderView?.mobile,
          traderName: traderView?.traderName,
          ors: traderView?.ors,
          profilePic: profile,
          regType: selectedOption,
          relMgrId: traderView?.relMgrId,
          shopNum: traderView?.shopNum,
          // status: `${traderView?.status === "Active" ? "ACTIVE" : "PENDING"}`,
          tradeLimit: traderView?.tradeLimit,
          tradeName: traderView?.tradeName,
          tradeScode: traderView?.tradeScode,
          tradeType: traderView?.tradeType,
          traderId: traderView?.traderId,
          tradeDesc: traderView?.tradeDesc,
          traderStatusId: traderView?.traderStatusId,
        },
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          onClose();
          onRefresh!();
          navigate(`/allDetails/${traderView?.traderId}`);
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } else {
      handlePostCall({
        URL: `admin/trade-platform/traders/register`,
        apiParams: {
          address: {
            addressLine: traderView?.addressLine,
            dist: traderView?.dist,
            pincode: traderView?.pincode,
            state: traderView?.state,
          },
          adminId: adminId,
          altMobile: traderView?.altMobile,
          kycDocs: images,
          marketId: traderView?.marketId,
          mobile: traderView?.mobile,
          traderName: traderView?.traderName,
          ors: traderView?.ors,
          profilePic: profile,
          regType: selectedOption,
          relMgrId: traderView?.relMgrId,
          shopNum: traderView?.shopNum,
          // status: `${traderView?.status === "Active" ? "ACTIVE" : "PENDING"}`,
          tradeLimit: traderView?.tradeLimit,
          tradeName: traderView?.tradeName,
          tradeScode: traderView?.tradeScode,
          tradeType: traderView?.tradeType,
          traderId: 0,
          tradeDesc: traderView?.tradeDesc,
          traderStatusId: traderView?.traderStatusId,
        },
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          onClose();
          onRefresh!();
          navigate("/customers");
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.description);
        });
    }
  };

  // for radio buttons
  const handleChange = (value: string | number) => {
    setSelectedOption(value);
  };

  // for image upload
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(event.target.files[0]);
      onUploadReceiptImage("PROFILE_PIC", event.target.files);
    }
  };

  // Handler for button clicks
  const handleClick = (e: string) => {
    setActiveButton(e);
  };

  useEffect(() => {
    if (mode === "EDIT") {
      getTraderDetails(true);
    } else {
      getRmDetails();
      getMarketDetails();
      getStatusDetails();
    }
  }, []);

  const navigate = useNavigate();
  const [pincodeLength, setPincodeLength] = useState(false);

  function geocodeResponseToCityState(geocodeJSON: any) {
    //will return and array of matching {city,state} objects
    var parsedLocalities = [];
    if (geocodeJSON.results.length) {
      for (var i = 0; i < geocodeJSON.results.length; i++) {
        var result = geocodeJSON.results[i];
        var locality = {
          city: "",
          state: "",
        };
        for (var j = 0; j < result.address_components.length; j++) {
          var types = result.address_components[j].types;
          for (var k = 0; k < types.length; k++) {
            if (types[k] === "locality") {
              locality.city = result.address_components[j].long_name;
            } else if (types[k] === "administrative_area_level_1") {
              locality.state = result.address_components[j].short_name;
            }
          }
        }
        parsedLocalities.push(locality);

        //check for additional cities within this zip code
        if (result.postcode_localities) {
          for (var l = 0; l < result.postcode_localities.length; l++) {
            parsedLocalities.push({
              city: result.postcode_localities[l],
              dist: traderView?.dist,
              state: traderView?.state,
            });
          }
        }
      }
    } else {
      console.log("error: no address components found");
    }
    return parsedLocalities;
  }
  function fillCityAndStateFields(localities: any, pincode: any) {
    var locality = localities[0];
    // $("#city").val(locality.city);
    // $("#state").val(locality.state);
    var city = localities[0].city;
    setTraderView({
      ...traderView!,
      dist: city,
      state: locality.state,
      pincode: pincode,
    });
  }

  const onZip = (event: any) => {
    // var zip = $("#zip").val().replace(/[^\d]/g, "");
    let number = event;
    setTraderView({
      ...traderView!,
      pincode: number,
    });
    var api_key = "AIzaSyBw-hcIThiKSrWzF5Y9EzUSkfyD8T1DT4A";

    if (event.length === 0) {
      setTraderView({
        ...traderView!,
        dist: "",
        state: "",
        pincode: "",
      });
      setPincodeLength(false);
    } else if (event.length == 6) {
      //make a request to the google geocode api with the zipcode as the address parameter and your api key
      $.get(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          event +
          "&key=" +
          api_key
      ).then(function (response) {
        //parse the response for a list of matching city/state
        var possibleLocalities = geocodeResponseToCityState(response);
        fillCityAndStateFields(possibleLocalities, number);
      });
      setPincodeLength(false);
    } else {
      // console.log("error", zip.length);
      // $("#city").val("");
      // $("#state").val("");
      setPincodeLength(true);
    }
  };

  // for status
  const handleStatusClick = (s: any) => {
    setTraderView({
      ...traderView!,
      traderStatusId: s.id,
      status: s.status,
    });
    setOpenStatus(false);
    // setTraderStatus(traderStatus);
    setSelectStatus(s.status);
  }; // Display the name of the selected option

  //market model
  const [market, setMarket] = useState(false);
  const [selectedOpti, setSelectedOpti] = useState<number | null>(null);
  const clear = () => {
    setSelectedOpti(0);
    setSearchMrkt("");
    setTraderView({
      ...traderView!,
      marketId: 0,
      marketName: "",
    });
    // setMarket(false);
  };
  const handleMaretClick = (market: any) => {
    setSearchMrkt(market.marketName);
    setTraderView({
      ...traderView!,
      marketId: market?.marketId,
      marketName: market.marketName,
    });
    setMarket(false);
    let index = marketDetails.findIndex((m) => m.marketId == market.marketId);
    marketDetails.splice(index, 1);
    marketDetails.splice(0, 0, market);
    setMarketDetails(marketDetails);
  };

  const filteredMarkets = marketDetails.filter((option) =>
    option.marketName.toLowerCase().includes(searchMrkt.toLowerCase())
  );

  //RM dropdown
  const [rmgr, setRmgr] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState<number | null>(null);

  const clearSearch = () => {
    setSelectedOpt(0);
    setSearchTerm("");
    setTraderView({
      ...traderView!,
      relMgrId: 0,
      rmMobile: "",
      relMgr: "",
    });
    // setRmgr(false);
  };

  const handleOptionClick = (rm: any) => {
    setSearchTerm(rm.name);
    setTraderView({
      ...traderView!,
      rmMobile: rm.mobile,
      relMgrId: rm.id,
      relMgr: rm.name,
    });
    setRmgr(false);
    let index = relationshipManager.findIndex((r) => r.id == rm.id);
    relationshipManager.splice(index, 1);
    relationshipManager.splice(0, 0, rm);
    setRelationshipManager(relationshipManager);
  }; // Display the name of the selected option

  const filteredOptions = relationshipManager.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="m-2">
      <div className="modal-header m-4 border-b flex justify-between items-center">
        {mode !== "EDIT" ? (
          <span className="font-semibold m-2 text-[20px]">Add Trader</span>
        ) : (
          <span className="font-semibold m-2 text-[20px]">
            Edit -{" "}
            <span className="font-semibold text-lg">
              {traderView?.tradeName}
            </span>
          </span>
        )}
      </div>
      <div
        className="flex overflow-y-auto"
        style={{ height: "calc(100vh - 15rem)" }}
      >
        <div className="ml-[20px] mr-[10px] w-auto h-auto text-sm">
          <div className="modal-body flex justify-between">
            <div>
              <span className="font-semibold">
                Trader Type <span className="color-red">{"*"}</span>
              </span>
              <div className="mt-[10px] flex">
                <button
                  className={`border-2 rounded w-[200px] h-[40px] ${
                    activeButton === "DEST" ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => {
                    handleClick("DEST");
                    setTraderView({
                      ...traderView!,
                      tradeType: "DEST",
                    });
                  }}
                >
                  Buy (Destination Trader)
                </button>
                <button
                  className={`border-2 rounded w-[200px] h-[40px] ml-3 ${
                    activeButton === "SOURCE" ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => {
                    handleClick("SOURCE");
                    setTraderView({
                      ...traderView!,
                      tradeType: "SOURCE",
                    });
                  }}
                >
                  Sell (Source Trader)
                </button>
              </div>
            </div>

            <div className="">
              <label className="text-sm font-semibold">
                Business Type <span className="color-red">{"*"}</span>
              </label>
              <RadioButton
                displayType="horizontal"
                data={[
                  { label: "INDIVIDUAL", value: "INDIVIDUAL" },
                  { label: "PROPRIETOR", value: "PROPRIETOR" },
                  { label: "PARTNERSHIP", value: "PARTNERSHIP" },
                  { label: "LLP", value: "LLP" },
                  { label: "PRIVATE LIMITED", value: "PRIVATE LIMITED" },
                ]}
                value={selectedOption}
                pointer={{ label: "label", value: "value" }}
                onChange={(value) => {
                  handleChange(value);
                  setTraderView({
                    ...traderView!,
                    businessType: value,
                  });
                }}
                styles={{ display: "flex", gap: "1rem", paddingTop: "0.5rem" }}
              />
            </div>

            <div>
              <div className="">
                <span className="text-sm font-semibold">Status</span>

                <div
                  className="w-[160px] h-[30px] border-gray br-5 cursor-pointer mt-1.5"
                  onClick={() => {
                    setOpenStatus(true);
                  }}
                >
                  <div className="relative flex justify-between">
                    <span className="ml-1 mt-0.5 text-base">
                      {traderView?.status}
                    </span>
                    <span>
                      <FaAngleDown className="mt-[5px] mr-2 cursor-pointer absolute right-0" />
                    </span>
                  </div>
                </div>
              </div>

              {/* status popover */}
              <div>
                <Popover
                  open={openStatus}
                  onClose={() => {
                    setOpenStatus(false);
                  }}
                  className="w-[160px] ml-[80px] h-auto"
                >
                  <div>
                    {traderStatus.map((status) => (
                      <div
                        className="p-1"
                        onClick={() => handleStatusClick(status)}
                      >
                        {/* {status.status === "ACTIVE" && (
                          <span className="ml-2 cursor-pointer color-primary font-semibold">
                            {status.status}
                          </span>
                        )}

                        {status.status === "UNDER REVIEW" && (
                          <span className="ml-2 cursor-pointer color-orange font-semibold">
                            {status.status}
                          </span>
                        )}

                        {status.status === "HOLD" && (
                          <span className="ml-2 cursor-pointer font-semibold">
                            {status.status}
                          </span>
                        )}

                        {status.status === "REJECTED" && (
                          <span className="ml-2 cursor-pointer color-red font-semibold">
                            {status.status}
                          </span>
                        )} */}
                        <span className="font-semibold text-base p-2 ml-2 cursor-pointer">
                          {status.status}
                        </span>
                      </div>
                    ))}
                  </div>
                </Popover>
              </div>
            </div>
          </div>

          <div className="mb-[10px] mt-[15px] font-semibold">
            <span className="text-[17px]">
              Relationship Manager<span className="color-red">{"*"}</span>
            </span>
            <div className="border-b mt-2"></div>
          </div>
          <div className="flex mt-[10px]">
            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  Relationship Manager<span className="color-red">{"*"}</span>
                </span>
              </div>

              <div
                className="w-[270px] h-[45px] mr-[20px] border-gray cursor-pointer br-5"
                onClick={() => {
                  setRmgr(true);
                  setSearchTerm("");
                }}
              >
                <div className="relative flex items-center h-full">
                  {/* <Input
                    type="text"
                    value={traderView?.relMgr}
                    onChange={(e) => {
                      setTraderView({
                        ...traderView!,
                        relMgr: e.target.value,
                      });
                    }}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    className="cursor-pointer"
                  /> */}
                  <span className="text-center ml-2 text-sm font-semibold">
                    {traderView?.relMgr}
                  </span>

                  <span className="absolute top-2 right-0">
                    <ArrowDropDownIcon />
                  </span>
                </div>
              </div>

              {/* relationship manager dropdown */}

              <div className="ml-[135px]">
                <Popover
                  open={rmgr}
                  onClose={() => {
                    setRmgr(false);
                  }}
                  className="w-[270px] h-auto"
                >
                  <div>
                    <div className=" relative m-2">
                      <FaSearch
                        className=" absolute color-primary"
                        size={15}
                        style={{ top: "14px", left: "6px" }}
                      />
                      <Input
                        type="text"
                        palceholder="Search RM Name"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        inputStyle={{
                          textIndent: "22px",
                          padding: "8px 5px",
                        }}
                        style={{
                          width: "95%",
                        }}
                      />

                      <div>
                        <IoMdCloseCircleOutline
                          onClick={() => {
                            clearSearch();
                          }}
                          className="absolute color-primary cursor-pointer w-10"
                          style={{
                            top: "32%",
                            right: "1rem",
                            fontSize: "1.2rem",
                            color: "gray",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="overflow-y-auto p-[5px]"
                      style={{ height: "calc(50vh - 5rem)" }}
                    >
                      {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, id) => (
                          <div
                            onClick={() => handleOptionClick(option)}
                            className="cursor-pointer p-1"
                          >
                            <div
                              className={`flex border-gray br-5 mb-1 p-2 ${
                                traderView?.relMgrId === option.id
                                  ? "bg-light-primary border-primary"
                                  : "bg-white"
                              } hv-primary cursor-pointer`}
                            >
                              <div>
                                {option.profilePic ? (
                                  <img
                                    src={option.profilePic}
                                    className="w-8 h-8 mt-1.5"
                                  />
                                ) : (
                                  <img
                                    src={EmptyProfile}
                                    className="w-8 h-8 mt-1.5"
                                  />
                                )}
                              </div>
                              <div className="ml-2">
                                <span className="font-semibold">
                                  {option?.name}
                                </span>
                                <br />
                                <span className="text-sm font-semibold">
                                  {option?.mobile}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex items-center justify-center h-full">
                          <span className="font-semibold">No RM found</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Popover>
              </div>
            </div>

            <div>
              <div className=" mb-1">
                <span className="text-sm font-semibold">
                  RM Mobile Number<span className="color-red">{"*"}</span>
                </span>
              </div>

              <div className="w-[270px] h-[45px] mr-[20px] mb-4 border border-gray br-5">
                {/* <Input
                  type="number" */}
                <div className="flex items-center h-full">
                  <span className="text-center text-sm font-semibold ml-2">
                    {traderView?.rmMobile}
                  </span>
                </div>

                {/* onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      rmMobile: e.target.value,
                    });
                  }}
                /> */}
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  Trade Limit<span className="color-red">{"*"}</span>
                </span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px] mb-4">
                <Input
                  className="text-sm font-semibold"
                  type="number"
                  value={traderView?.tradeLimit}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      tradeLimit: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                {" "}
                <span className="text-sm font-semibold">
                  ORS-ONO RISK SCORE
                </span>
              </div>
              <div className="w-[453px] h-[40px] mb-4">
                <Input
                  className="text-sm font-semibold"
                  type="number"
                  value={traderView?.ors}
                  onChange={(e) => {
                    console.log(e);
                    setTraderView({
                      ...traderView!,
                      ors: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="mb-2">
              {/* className="text-[17px]" */}
              <span className="text-[17px] font-semibold">
                Business Details<span className="color-red">{"*"}</span>
              </span>
            </div>
            <div className="border-b"></div>

            <div className="flex">
              <div>
                <div className="flex mt-[20px]">
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Trade Name<span className="color-red">{"*"}</span>
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.tradeName}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            tradeName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Trader Name<span className="color-red">{"*"}</span>
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.traderName}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            traderName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Trade Short Code
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.tradeScode}
                        onChange={(e) => {
                          const value = e.target.value;

                          // No validation here, so special characters are allowed
                          setTraderView({
                            ...traderView!,
                            tradeScode: value, // Directly set the value, allowing special characters
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Trade Type<span className="color-red">{"*"}</span>
                      </span>
                    </div>

                    <div className="w-[270px] h-[45px] mr-[20px] mb-4 border border-gray br-5">
                      {/* <Input
                        type="text"
                        value={traderView?.tradeType} */}

                      <div className="flex items-center h-full">
                        <span className="text-center text-sm font-semibold ml-2">
                          {traderView?.tradeType}
                        </span>
                      </div>
                      {/* onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            tradeType: e.target.value,
                          });
                        }}
                        // disabled={true}
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="flex mt-[10px]">
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Market Name<span className="color-red">{"*"}</span>
                      </span>
                    </div>
                    <div
                      className="w-[270px] h-[45px] mr-[20px] border-gray br-5 cursor-pointer"
                      onClick={() => {
                        setMarket(true);
                        setSearchMrkt("");
                      }}
                    >
                      <div className="relative flex items-center h-full">
                        {/* <Input
                          type="text"
                          value={traderView?.marketName}
                          // onChange={(e) => {
                          //   setTraderView({
                          //     ...traderView!,
                          //     marketName: e.target.value,
                          //   });
                          // }}
                          // onChange={(e) => setSearchMrkt(e.target.value)}
                        /> */}
                        <span className="text-center ml-2 font-medium text-sm">
                          {traderView?.marketName}
                        </span>

                        <span className="absolute top-2 right-0">
                          <ArrowDropDownIcon />
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* market popover */}
                  <div>
                    <Modal
                      open={market}
                      onClose={() => {
                        setMarket(false);
                      }}
                      width="30vw"
                      height="70vh"
                    >
                      <>
                        <ModalHeader title={"Select Market"} content={<></>} />
                        <div className="">
                          <div className="flex gap-4 m-3 relative">
                            <FaSearch
                              className="absolute color-primary"
                              size={15}
                              style={{ top: "14px", left: "6px" }}
                            />
                            <Input
                              type="text"
                              palceholder="Search by Market Name"
                              value={searchMrkt}
                              onChange={(e) => {
                                setSearchMrkt(e.target.value);
                              }}
                              inputStyle={{
                                textIndent: "22px",
                                padding: "8px 5px",
                              }}
                              style={{
                                width: "99%",
                              }}
                            />

                            <IoMdCloseCircleOutline
                              onClick={() => {
                                clear();
                              }}
                              className="absolute color-primary cursor-pointer w-10"
                              style={{
                                top: "32%",
                                right: "1.5rem",
                                fontSize: "1.2rem",
                                color: "gray",
                                cursor: "pointer",
                              }}
                            />
                          </div>

                          <div
                            className="flex overflow-y-auto border-gray m-3 br-10"
                            style={{ height: "calc(90vh - 15rem)" }}
                          >
                            <div className="w-[100%]">
                              {filteredMarkets.length > 0 ? (
                                filteredMarkets.map((option) => (
                                  <div
                                    className={`flex border-b p-2 cursor-pointer hv-primary cursor-pointer ${
                                      traderView?.marketId === option.marketId
                                        ? "bg-light-primary w-full p-2 border-primary br-10"
                                        : "bg-white"
                                    }`}
                                    onClick={() => handleMaretClick(option)}
                                  >
                                    <div
                                      className={`flex items-center br-5 mb-1 p-0.5`}
                                    >
                                      <img
                                        src={marketImgg}
                                        alt=""
                                        className="h-7 w-7"
                                      />
                                      <span className="ml-3 font-semibold">
                                        {option.marketName}
                                      </span>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="flex items-center justify-center h-full">
                                  <span className="font-semibold">
                                    No Market Found
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    </Modal>
                  </div>

                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">Shop Number</span>
                    </div>
                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="text"
                        value={traderView?.shopNum}
                        onChange={(e) => {
                          const value = e.target.value;

                          // No validation here, so special characters are allowed
                          setTraderView({
                            ...traderView!,
                            shopNum: value, // Directly set the value, allowing special characters
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Mobile Number<span className="color-red">{"*"}</span>
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px] mr-[20px]">
                      <Input
                        className="text-sm font-semibold"
                        type="number"
                        value={traderView?.mobile}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            mobile: e.target.value,
                          });
                        }}
                      />
                      {traderView?.mobile &&
                      traderView.mobile.length > 0 &&
                      traderView.mobile.length < 10 ? (
                        <span className=" text-xs color-red">
                          Minimum Mobile Number length should be 10
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="mb-1">
                      <span className="text-sm font-semibold">
                        Alternate Mobile
                      </span>
                    </div>

                    <div className="w-[270px] h-[40px]">
                      <Input
                        className="text-sm font-semibold"
                        type="number"
                        value={traderView?.altMobile}
                        onChange={(e) => {
                          setTraderView({
                            ...traderView!,
                            altMobile: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Profile pic upload and preview */}
              <div className="relative w-[160px] h-[155px] ml-[22px] mt-[20px] border-gray br-5">
                <div
                  className="h-[100px] w-[80px] ml-[40px] mt-[20px]"
                  style={{
                    width: "90px",
                    height: "90px",
                  }}
                >
                  {(profile === "" || profile === null) && (
                    <img
                      src={EmptyProfile}
                      alt=""
                      className="w-[70px] h-[70px] mt-1 mr-2"
                    />
                  )}
                  {(profile !== "" || profile === null) && (
                    <img
                      src={profile}
                      className="h-[80px] w-[80px]"
                      style={{
                        borderRadius: "100%",
                      }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  ref={ref}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <button
                  onClick={() => {
                    ref.current?.click();
                  }}
                  className="absolute bottom-0 color-blue ml-3.5 border-gray br-5 fit-content"
                >
                  Choose From Library
                </button>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className="mb-1">
              <span className="text-sm font-semibold">
                Trade Description<span className="color-red">{"*"}</span>
              </span>
            </div>
            <div className="w-[270px] h-[40px] mr-[20px]">
              <Input
                className="text-sm font-semibold"
                type="text"
                value={traderView?.tradeDesc}
                onChange={(e) => {
                  setTraderView({
                    ...traderView!,
                    tradeDesc: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="font-semibold mt-[20px] mb-[10px]">
            <span className="text-[17px]">
              Business Address<span className="color-red">{"*"}</span>
            </span>
          </div>
          <div className="border-b"></div>
          <div className="flex mt-[12px]">
            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  Pincode<span className="color-red">{"*"}</span>
                </span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.pincode}
                  onChange={(e) => {
                    // setTraderView({
                    //   ...traderView!,
                    //   pincode: e.target.value,
                    // });
                    onZip(e.target.value);
                    console.log(e.target.value);
                  }}
                />
                {pincodeLength ? (
                  <span className="color-red">
                    Minimum pincode length should be 6
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  State<span className="color-red">{"*"}</span>
                </span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.state}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      state: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  District<span className="color-red">{"*"}</span>
                </span>
              </div>

              <div className="w-[270px] h-[40px] mr-[20px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.dist}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      dist: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb-1">
                <span className="text-sm font-semibold">
                  Street & Village<span className="color-red">{"*"}</span>
                </span>
              </div>

              <div className=" w-[456px] h-[40px]">
                <Input
                  className="text-sm font-semibold"
                  type="text"
                  value={traderView?.addressLine}
                  onChange={(e) => {
                    setTraderView({
                      ...traderView!,
                      addressLine: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-[20px] font-semibold">
            <span className="text-[17px] mb-2">
              Documents<span className="color-red">{"*"}</span>
            </span>
            <div className="border-b mt-2"></div>
          </div>
          <div className="">
            <div className="flex mt-[16px] mb-[16px]">
              <div>
                <div className="mb-1">
                  <span className="font-semibold">
                    Aadhar Card<span className="color-red">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[560px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  {/* <div className="w-[156px] h-[100px]"> */}
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      // onChange={(e) => {
                      //   onUploadReceiptImage(
                      //     "AADHAR",
                      //     e.target.files ? e.target.files[0] : null
                      //   );
                      // }}

                      onChange={(e) => {
                        if (e.target.files) {
                          // Pass the whole FileList to the function
                          onUploadReceiptImage("AADHAR", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {/* </div> */}
                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "AADHAR" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "33.333%",
                            height: "8rem",
                          }}
                          key={`AADHAR-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={
                                image.url.includes(".pdf") ? "pdf" : "image"
                              }
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "AADHAR")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`AADHAR-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={
                              image.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="ml-[20px]">
                <div className="mb-1">
                  <span className="font-semibold">
                    Permanent Account Number(PAN)
                    <span className="color-red">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[746px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  {/* <div className="w-[156px] h-[100px]"> */}
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      // onChange={(e) => {
                      //   onUploadReceiptImage(
                      //     "PAN",
                      //     e.target.files ? e.target.files[0] : null
                      //   );
                      // }}

                      onChange={(e) => {
                        if (e.target.files) {
                          // Pass the whole FileList to the function
                          onUploadReceiptImage("PAN", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {/* </div> */}
                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "PAN" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "33.333%",
                            height: "8rem",
                          }}
                          key={`PAN-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "PAN")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`PAN-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="border-b mt-[10px]"></div>

            <div className="flex mt-[16px]">
              <div className="mt-2">
                <div className="mb-1">
                  <span className="font-semibold">
                    Bank Statement<span className="color-red">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[560px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  {/* <div className="w-[156px] h-[100px]"> */}
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      // onChange={(e) => {
                      //   onUploadReceiptImage(
                      //     "BANK_STATEMENT",
                      //     e.target.files ? e.target.files[0] : null
                      //   );
                      // }}

                      onChange={(e) => {
                        if (e.target.files) {
                          // Pass the whole FileList to the function
                          onUploadReceiptImage(
                            "BANK_STATEMENT",
                            e.target.files
                          );
                        }
                      }}
                    />
                  </div>
                  {/* </div> */}
                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter(
                        (doc) => doc.type == "BANK_STATEMENT" && doc.status != 0
                      )
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "33.333%",
                            height: "8rem",
                          }}
                          key={`BANK_STATEMENT-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "BANK_STATEMENT")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`BANK_STATEMENT-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="ml-[20px] mt-2">
                <div className="mb-1">
                  <span className="font-semibold">ITR Document</span>
                </div>

                <div className="flex w-[746px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  {/* <div className="w-[156px] h-[100px]"> */}
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      // onChange={(e) => {
                      //   onUploadReceiptImage(
                      //     "ITR",
                      //     e.target.files ? e.target.files[0] : null
                      //   );
                      // }}

                      onChange={(e) => {
                        if (e.target.files) {
                          // Pass the whole FileList to the function
                          onUploadReceiptImage("ITR", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {/* </div> */}
                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "ITR" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "33.333%",
                            height: "8rem",
                          }}
                          key={`ITR-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "ITR")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`ITR-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="border-b mt-[10px]"></div>

            <div className="flex mt-[16px]">
              <div className="mt-2">
                <div className="mb-1">
                  <span className="font-semibold">
                    Trade/Mandi License<span className="color-red">{"*"}</span>
                  </span>
                </div>

                <div className="flex w-[560px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  {/* <div className="w-[156px] h-[100px]"> */}
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      // onChange={(e) => {
                      //   onUploadReceiptImage(
                      //     "TRADE_LICENSE",
                      //     e.target.files ? e.target.files[0] : null
                      //   );
                      // }}

                      onChange={(e) => {
                        if (e.target.files) {
                          // Pass the whole FileList to the function
                          onUploadReceiptImage("TRADE_LICENSE", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {/* </div> */}
                  {traderView?.kycDocs != null &&
                    traderView?.kycDocs
                      .filter(
                        (doc) => doc.type == "TRADE_LICENSE" && doc.status != 0
                      )
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "33.333%",
                            height: "8rem",
                          }}
                          key={`TRADE_LICENSE-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "TRADE_LICENSE")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`TRADE_LICENSE-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="ml-[20px] mt-2">
                <div className="mb-1">
                  <span className="font-semibold">Other Documents</span>
                </div>

                <div className="flex w-[746px] h-[170px] bg-white p-4 border border-gray-300 rounded-lg overflow-auto">
                  {/* <div className="w-[156px] h-[100px]"> */}
                  <div className="shrink-0 overflow-hidden p-2">
                    <FileUploader
                      // onChange={(e) => {
                      //   onUploadReceiptImage(
                      //     "MANDI_LICENSE",
                      //     e.target.files ? e.target.files[0] : null
                      //   );
                      // }}

                      onChange={(e) => {
                        if (e.target.files) {
                          // Pass the whole FileList to the function
                          onUploadReceiptImage("OTHER", e.target.files);
                        }
                      }}
                    />
                  </div>
                  {/* </div> */}
                  {traderView?.kycDocs &&
                    traderView?.kycDocs
                      .filter((doc) => doc.type == "OTHER" && doc.status != 0)
                      .map((image, i) => (
                        <div
                          className="shrink-0 overflow-hidden p-2 rounded relative"
                          style={{
                            width: "33.333%",
                            height: "8rem",
                          }}
                          key={`OTHER-${i}`}
                        >
                          <a href={image.url} target="_blank">
                            <DocumentPreview
                              url={image.url}
                              docType={image.docType}
                            />
                          </a>
                          <span
                            className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                            style={{
                              background: "#ff0000de",
                            }}
                            onClick={() => {
                              onRemoveReceiptImage(image.url, true, image.id);
                            }}
                          >
                            <FaXmark style={{ color: "white" }} />
                          </span>
                        </div>
                      ))}
                  {kycDocs
                    .filter((doc) => doc.type == "OTHER")
                    .map((image, i) => (
                      <div
                        className="shrink-0 overflow-hidden p-2 rounded relative"
                        style={{
                          width: "33.333%",
                          height: "8rem",
                        }}
                        key={`OTHER-${i}`}
                      >
                        <a href={image.url} target="_blank">
                          <DocumentPreview
                            url={image.url}
                            docType={image.docType}
                          />
                        </a>
                        <span
                          className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                          style={{
                            background: "#ff0000de",
                          }}
                          onClick={() => {
                            onRemoveReceiptImage(image.url);
                          }}
                        >
                          <FaXmark style={{ color: "white" }} />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b mt-2"></div>
      <div className="modal-header p-2 flex justify-between">
        <div>
          <Button
            text="CANCEL"
            className="w-[100px] ml-[10px]"
            variant="primaryLight"
            onClick={() => onClose()}
          />
        </div>
        <div>
          <Button
            text="SUBMIT"
            className="w-[100px] mr-[10px]"
            onClick={() => {
              onSubmit();
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AddTrader;
